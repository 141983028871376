import axios from 'axios';
import { useState } from 'react';
import { ImCross } from 'react-icons/im';

const ForgotModel = ({ forgot, setForgot, setValue }) => {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

  const handleClose = () => {
    setValue(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (email === "") {
      alert("Please enter an email address");
      setLoading(false);
      return;
    }
    const body = {
      email: email,
    };
    try {
      const response = await axios.post(`${BASE_URL}/api/user/password/reset-email/`, body);
      const res = await response.data;
      alert(res?.msg);
      setLoading(false);
      setSent(true);
    } catch (error) {
      setLoading(false);
      alert(error?.response?.data?.non_field_errors[0]);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      {sent ? (
        <div className="w-[90%] max-w-[700px] h-[592px] bg-white rounded-[24px] p-6 relative">
          <div className="flex justify-end">
            <ImCross className="w-3 h-3 cursor-pointer mt-4 text-[#ADADAD]" onClick={handleClose} />
          </div>
          <div className="text-center">
            <h1 className="text-2xl font-semibold mb-4 text-[#2f2f2f]">Email Sent</h1>
            <p className="mb-4">We have sent a link to <span className="font-bold">{email}</span> to reset your password.</p>
            <button className="bg-custom-btn text-white py-2 px-6 rounded-md">Check your email</button>
          </div>
        </div>
      ) : (
        <div className="w-[90%] max-w-[700px] bg-white p-6 rounded-lg relative">
          <div className="flex justify-end">
            <ImCross className="w-3 h-3 cursor-pointer mt-4 text-[#ADADAD]" onClick={handleClose} />
          </div>
          <h1 className="text-2xl font-bold text-center mb-6">Forgot Password?</h1>
          <div className="flex flex-col items-center">
            <div className="mb-4 w-full max-w-md">
              <label className="block font-semibold text-[#474747] text-sm mb-2">Email ID<span className="text-red-600">*</span></label>
              <input
                type="email"
                placeholder="Name@gmail.com"
                className="outline-none border-[#DDDDDE] border-[1px] w-full rounded-[4px] py-2 px-3"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <button
              onClick={handleSubmit}
              disabled={loading}
              className={`py-2 w-full max-w-md rounded-md ${email === "" ? 'bg-[#949494] cursor-not-allowed' : 'bg-custom-btn text-white'}`}
            >
              {loading ? 'Loading.....' : 'Request a reset link'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotModel;
