import { useEffect } from "react";
import PageTopNavigation from "../components/PageTopNavigation";
import QandA from "../components/QandA";
import { useGetAllPrivateQuery } from "../redux/policyrtk/PrivatePolicy";
function PrivacyPolicy() {
    const {data} = useGetAllPrivateQuery()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
        <div className="mt-[70px] md:mt-0 ml-10 md:ml-3">
        <PageTopNavigation firstRouteLink={'/'} firstRouteName={'Home'} secondRouteName={'Privacy policy'}  />
        </div>
        <div className="md:mx-[12px] mt-[50px] md:mt-1 mx-[60px] mb-24 text-custom-text-p">

            {/* For Navigation */}


            {/* Heading */}
            <h2 className="md:text-[24px] md:text-black md:font-[500]  text-[36px] text-[#2f2f2f] font-medium">Privacy policy</h2>

            {data?
                data?.map((item,index)=>(
                    <QandA key={index} title={item.title} subject={item.subject} />
                ))
                    :".....LOADING"
            }

        </div>
        </>
    )
}

export default PrivacyPolicy