import React, { useEffect, useState } from 'react'
import Logo from '../assets/img/Logo.png'
import { RxCross1 } from 'react-icons/rx';
import { FiSearch } from 'react-icons/fi';
import { useGetAllCategoryQuery, useSearchedProductsMutation } from '../redux/productrtk/Product';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchLoading, setSearchProducts, setSearchTerm } from '../redux/slices/search'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";

const SearchOverlay = ({visibility, setVisibility}) => {

    const [SearchProdutApi] = useSearchedProductsMutation();
    const [focus, setFocus] = useState(false);
    const {data: categories, isLoading:categoryLoading} = useGetAllCategoryQuery();
    console.log("Categories", categories);
    const [search, setSearch] = useState('');
    const [sliderIndex, setSliderIndex] = useState(0);
    const handleSlideChange = (swiper) => {
        setSliderIndex(swiper.realIndex);
    };
    const navigate = useNavigate()
    const dispatch = useDispatch();
    useEffect(()=>{
        // if(visibility)
        // {
        //     document.body.style.overflow = "hidden";
        // }
        // else
        // {
        //     document.body.style.overflow = "auto";
        // }
    }, [visibility])

    const handleChange = (e) => {
        setSearch(e.target.value);
        dispatch(setSearchTerm(e.target.value))
    }
    
    const searched = useSelector((state)=> state.search.searched);
    console.log("Search Term", searched);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
    };
    
    const handleSearch = async() => {
        if(searched) {
          navigate('/searchProduct');
        }
        dispatch(setSearchLoading(true));
        try {
          const searchBody = {
            search_name: searched
          }
    
          if(!searched) {
            toast.warning('Enter Search Query', {
              position: 'top-center',
              autoClose: 3000,
            });
          }
    
          if(searched) {
            setVisibility(!visibility);
            const response = await SearchProdutApi(searchBody);
            if(response) {
              dispatch(setSearchProducts(response));
            }
            dispatch(setSearchTerm(""))
          }
          
        } catch(error) {
          console.log(error);
        }
        dispatch(setSearchLoading(false));
    }
    var updatedHeaderData  = categories?.filter((i) => i.parent == null);
    console.log("Focus", focus);

    const handleSubCategoryClick = (item) => {
        setVisibility(!visibility);
        navigate(`/allproducts/${item?.category_name?.split(' ')?.join('')?.toLowerCase()}`);
    }

  return (
        <div className={`${visibility ? 'flex' : 'hidden'}`}>
            <div className={`${visibility ? 'fixed top-0 left-0 right-0 w-screen h-screen bg-black opacity-60' : 'w-0 h-0'}`} onClick={() => setVisibility(!visibility)}></div>
            <div className="z-[151] bg-white w-full flex flex-col gap-y-1 items-start pb-5">
                <div className='flex flex-row items-center justify-between w-11/12 mx-auto'>
                    <img src={Logo} className='h-3/4'/>
                    <div className='bg-[#00a6db] rounded-full p-2 text-white' onClick={() => setVisibility(!visibility)}>
                        <RxCross1/>
                    </div>
                </div>

                <div className='w-full border-grayMid border-t-[1px] pb-5'>

                </div>

                <div className={`flex items-center py-[15px] h-[37px] px-[24px] border rounded-full w-11/12 mx-auto border-grayMid ${focus ? 'shadow-[0px_0px_3px_1px_#00a6db]' : ''}`}>
                    <input
                    placeholder="Search Products..."
                    className={`w-full focus:outline-none`}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    />
                    <div onClick={handleSearch} className=""><FiSearch className="text-2xl text-custom-text-grey cursor-pointer w-full"/></div>
                </div>

                <div className='w-11/12 mx-auto pt-3'>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={10}
                        pagination={false}
                        keyboard={true}
                        mousewheel={{forceToAxis:true,thresholdDelta:30}}
                        onSlideChange={(swiper) => handleSlideChange(swiper)}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        breakpoints={{
                            370:{
                                slidesPerView:3,
                            },
                            400:{
                                slidesPerView:3,
                            },
                            500: {
                                slidesPerView: 4,
                            },
                            640:{
                                slidesPerView:4,
                            }
                        }}
                        className="mySwiper"
                    >
                        {
                            updatedHeaderData?.map((item) => (
                                <SwiperSlide>
                                    <div className='border-[1px] rounded-2xl px-2 py-1 text-[14px]' onClick={() => handleSubCategoryClick(item)}>
                                        {item?.category_name}
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />
        </div>
  )
}

export default SearchOverlay