import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  useGetAllCategoryQuery,
  useGetAllProductQuery,
  useGetIndustryFilterQuery,
  useGetSubCategoryQuery,
} from "../../redux/productrtk/Product";
import "../../App.css";
import axios from "axios";

function LeftAccordion({ setApiData, apiData }) {
  const { category, industry } = useParams();
  const navigate = useNavigate();

  // Determines the class for active items
  function decideColor(arg) {
    if (arg?.toLowerCase() === category?.toLowerCase() || arg?.toLowerCase() === industry?.toLowerCase()) {
      return "text-custom-text"; // Apply active class (e.g., blue color)
    }
    return ""; // Default class if not active
  }

  const { data: products, refetch: productRefetch } = useGetAllProductQuery();
  const { data: categories, isLoading: categoryLoading } = useGetAllCategoryQuery();
  const [industrys, setIndustrys] = useState([]);
  const [AnyTypeRadio, setAnyTypeRadio] = useState(true);
  const [id, setId] = useState(1);

  const { data: subCategory, isLoading: subCategoryLoading, refetch } = useGetSubCategoryQuery(id);
  const [subCategoryChangeLoading, setSubCategoryChangeLoading] = useState(false);

  useEffect(() => {
    const fetchAccData = async () => {
      setSubCategoryChangeLoading(true);
      await refetch();
      setSubCategoryChangeLoading(false);
    };
    fetchAccData();
  }, [id]);

  const handleClick = async () => {
    setAnyTypeRadio(true);
    productRefetch();
    setApiData(products);
    navigate("/allproducts");
  };

  useEffect(() => {
    if (!products) return;

    const filteredProducts = products.filter((product) => {
      const matchesIndustry = industry
        ? product?.industry_categories?.some(
            (industryCategory) =>
              industryCategory?.industry_category_name?.toLowerCase() ===
              industry?.toLowerCase()
          )
        : true;

      const matchesCategory = category
        ? product?.category_name?.split(" ")?.join("")?.toLowerCase() ===
          category?.split(" ")?.join("")?.toLowerCase()
        : true;

      return matchesIndustry && matchesCategory;
    });

    setApiData(filteredProducts);
  }, [industry, category, products]);

  const handleSubCategoryClick = (item) => {
    const data = products?.filter((d) => d?.title === item?.title);
    setApiData(data);
  };

  useEffect(() => {
    const FetchIndustry = async () => {
      try {
        const response = await axios.get(
          "https://api.packagingsimplified.com/api/product/industry-category/"
        );
        setIndustrys(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    FetchIndustry();
  }, []);

  const handleNavigateIndusstry = (industryname) => {
    setAnyTypeRadio(false);
    navigate(`/allproducts/industry/${industryname}`);
  };

  return (
    <div className="w-fit">
      <Link
        to={"/allproducts"}
        className={`text-[20px] hover:text-custom-text font-medium ${!category && !industry ? "text-custom-text" : ""}`}
        onClick={handleClick}
      >
        All Products
      </Link>

      <Accordion allowToggle>
        {categories?.filter(i => i.parent == null && i?.category_name != "Industry" && i.category_name != "Functions")?.map((accItem) => (
          <AccordionItem className="mt-[16px]" key={accItem?.id}>
            <Link
              className="hover:text-custom-text transition duration-300"
              to={`/allproducts/${accItem.category_name}`}
            >
              <AccordionButton
                onClick={() => {
                  setId(accItem?.id);
                }}
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  className={`text-[20px] font-normal hover:font-medium ${decideColor(accItem.category_name)}`}
                >
                  {accItem.category_name}
                </Box>
              </AccordionButton>
            </Link>

            <AccordionPanel
              pb={4}
              className="mt-[16px] border-l-2 border-grayMid flex flex-col pl-[20px]"
            >
              {subCategoryLoading || subCategoryChangeLoading ? (
                <div className="w-full">
                  <div className="dots"></div>
                </div>
              ) : (
                subCategory?.products?.map((item) => (
                  <span
                    key={item?.id}
                    className={`cursor-pointer text-[20px] font-[600] hover:text-custom-text transition duration-300 mb-[10px] ${decideColor(item.title)}`}
                    onClick={() => handleSubCategoryClick(item)}
                  >
                    {item.title.charAt(0).toUpperCase() +
                      item.title.slice(1).toLowerCase()}
                  </span>
                ))
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      <div>
        <p className="text-[20px] font-[600] text-[#2F2F2F] mt-12 pb-3">
          Industry
        </p>
        <div className="w-[100%] h-[auto] border-l-2 border-grayMid flex-col pl-[20px]">
          <div className="flex gap-[10px] items-center mb-[16px]">
            <input
              type="radio"
              name="industry"
              id="special_input"
              checked={AnyTypeRadio}
              onClick={handleClick}
            />
            <p className="text-[20px] font-[400] text-[#2F2F2F]">Any</p>
          </div>
          {industrys?.industry_categories?.map((item, index) => (
            <div
              className="flex gap-[10px] items-center mb-[16px]"
              key={index}
            >
              <input
                type="radio"
                name="industry"
                id="special_input"
                value={item?.id}
                onClick={() =>
                  handleNavigateIndusstry(item?.industry_category_name)
                }
                checked={item?.industry_category_name.toLowerCase() === industry?.toLowerCase()}
              />
              <p
                className={`text-[20px] font-[400] text-[#2F2F2F] ${decideColor(item.industry_category_name)}`}
              >
                {item?.industry_category_name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LeftAccordion;

