import React from 'react'
import About1 from '../../assets/img/about_us_1.jpg'
import About2 from '../../assets/img/about_us_2.jpg'
import About3 from '../../assets/img/about_us_3.jpg'

const MobileAbout = ({title,data,image,images}) => {
  return (
    <>
    <div className='hidden md:block w-[100%]'>
        <div className='w-full ml-1 mr-1 mt-2 mb-2'>
            <h1 className='text-black pt-2 text-[12px] font-semibold'>{title}</h1>
            <p className='text-[12px] font-normal mt-1 leading-[21.6px]'>{data}</p>

            <div className="image w-[95%]  mt-2">
                 <img src={image?image:About1} className="w-[105%] h-[30vh] bg-[#D9D9D9] rounded-lg" />
            </div>

        </div>
    </div>
    
    <div className="hidden md:block mb-3 " style={{marginTop:"30px"}}>
    <div className="">
    <div className="flex justify-between items-center ">
                {images?.length>=2? (images?.map((image,index)=>(

                            <img src={image?.img} className="w-[30vw] h-[15vh]  ml-1  rounded-lg bg-[#D9D9D9]" />

                ))

           ):""

                }
                </div>
                </div>
                </div>
                {/* </div> */}

            

    </>
  )
}

export default MobileAbout