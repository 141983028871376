import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searchLoading: false,
    searchProduct: [],
    searched: "",
}

const  searchSlice = createSlice({
    name:'search',
    initialState,
    reducers:{
        setSearchProducts: (state, action) => {
            state.searchProduct = action.payload
        },
        setSearchTerm: (state, action) => {
            state.searched = action.payload
        },
        setSearchLoading: (state, action) => {
            state.searchLoading = action.payload
        },
    },
});

export const {setSearchProducts, setSearchTerm, setSearchLoading} = searchSlice.actions
export default searchSlice.reducer;
