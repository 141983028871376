import React, { useState, useRef, useEffect } from "react";
import Logo from "../assets/img/Logo.png";
import Login from "../authentication/Login";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BsTelephone,
  BsFillPersonFill,
  BsPerson,
  BsCart3,
} from "react-icons/bs";
import { IoMdCart } from "react-icons/io";
import navImg from "../assets/img/navImg.png";
import { BsArrowUpRight } from "react-icons/bs";
import SearchBtn from '../assets/img/icons/navbar_search.svg'
import CartBtn from '../assets/img/icons/navbar_cart.svg'
import { useContext } from "react";
import { MyContext } from "../context/Forgot";
import ForgotModel from "../authentication/ForgotModel";


import navPaperBag from "../assets/img/navPaperBag.png";
import tissuePapers from "../assets/img/tissuePapers.png";
import navButterPaper from "../assets/img/navButterPaper.png";
import navHangTags from "../assets/img/navHangTags.png";
import navHeaderCards from "../assets/img/navHeaderCards.png";
import navMailerBoxes from "../assets/img/navMailerBoxes.png";
import navPrintedTapes from "../assets/img/navPrintedTapes.png";
import navProductLables from "../assets/img/navProductLabels.png";
import navThankYouCard from "../assets/img/navThankYouCard.png";
import navWrappingCard from "../assets/img/navWrappingCard.png";
import Cart from '../assets/img/cart_icon.png'
import  {Link,useNavigate } from "react-router-dom";
import { setSearchLoading, setSearchProducts, setSearchTerm } from '../redux/slices/search'

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure
} from '@chakra-ui/react';

import { RxTextAlignJustify } from "react-icons/rx";


import { BsArrowRight } from "react-icons/bs";
import { useGetAllCategoryQuery, useGetAllParentCategoryQuery, useGetIndustryFilterQuery, useGetParentAllCategoryQuery, useSearchedProductsMutation } from "../redux/productrtk/Product";
import SearchOverlay from "./SearchOverlay";
import Accordion2 from "./accordian/Accordian2";
import MobileCategoryAccordian from "./accordian/MobileCategoryAccordian";

const Navbar = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [bulk, setBulk] = useState(false);
  const [a1, setA1] = useState(false);
  const [a2, setA2] = useState(false);
  const [a3, setA3] = useState(false);
  const [a4, setA4] = useState(false);
  const [a5, setA5] = useState(false);
  const [a6, setA6] = useState(false);
  const [search, setSearch] = useState('');
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const modalRef = useRef();
  const Token = localStorage.getItem("token");
  const RegToken = localStorage.getItem("Register_token");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [parentId,setparentId]=useState()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [SearchProdutApi] = useSearchedProductsMutation();
  const {data:categories, isLoading:categoryLoading} = useGetAllCategoryQuery();
  const {data:parentcategory} = useGetAllParentCategoryQuery()
  const {data:industrydata1,isLoading:industryloading} = useGetIndustryFilterQuery()
  const {data:parentcategorys}=useGetParentAllCategoryQuery(parentId)
  const [productCategory, setProductCategory] = useState();
  const [searchModal, setSearchModal] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [isProductDivVisible, setIsProductDivVisible] = useState(false);
  

  const handleMouseEnter = (category,id) => {
    setHoveredCategory(category);
    setIsProductDivVisible(true);
    setparentId(id)
  };

  const handleMouseLeave = () => {
    setIsProductDivVisible(false);
  };

  var headerData;
  headerData = categories?.filter((i) => i.parent == null && i?.category_name != "Industry" && i.category_name != 'Functions');

  useEffect(() => {
    setProductCategory(headerData);
  }, [categories])

  

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
      setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // Cleanup
  }, []);

  useEffect(() => {
    if(windowWidth <= 980) {
      const data = headerData?.slice(0,3);
      setProductCategory(data);
    } else if(windowWidth > 980) {
      const data = headerData;
      setProductCategory(data);
    }
  }, [windowWidth])

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowLogin(false);
    }
  };

  const handlemyaccount = ()=>{
    navigate("/myaccountDetail")
    onClose()
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (Token || RegToken) {
      setShowLogin(false);
    }
  }, [Token, RegToken]);

  const handleChange = (e) => {
    setSearch(e.target.value);
    
  }
  const searched = useSelector((state)=> state.search.searched);
  console.log("Search Term", searched);
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    if (!search.trim()) {
        toast.warning('Enter Search Query', {
            position: 'top-center',
            autoClose: 3000,
        });
        return;
    }

    dispatch(setSearchTerm(search)); // Update the Redux state with the search term

    navigate('/searchProduct');
    dispatch(setSearchLoading(true));
    try {
        const searchBody = {
            search_name: search // Use the search state here
        };

        const response = await SearchProdutApi(searchBody);
        if (response) {
            dispatch(setSearchProducts(response));
        }
    } catch (error) {
        console.log(error);
    } finally {
        dispatch(setSearchLoading(false));
    }

    setSearch(""); // Clear the search bar after the search is triggered
};


  const handleShop=()=>{
    onClose()
    navigate("/allproducts")
  }

  useEffect(() => {

  }, [searchModal])

  const handleNavigateproduct = (routes)=>{
    navigate(`/allproducts/${routes}`)
    setIsProductDivVisible(false)
  }

  const handlecategorynavigate = (routes)=>{
    navigate(`/allproducts/${routes}`)
    setIsProductDivVisible(false)
  }

  const handleindustrynavigate = (industryitem)=>{
    navigate(`/allproducts/industry/${industryitem}`)
  }

  useEffect(() => {
    const fetchPhoneNumber = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/productsheet/get-contact-info/`);
        const data = await response.json();
        setPhoneNumber(data.phone_number);
      } catch (error) {
        console.error("Failed to fetch phone number:", error);
      }
    };
    fetchPhoneNumber();
  }, []);

  return (
    <>
      {searchModal && <div className={` hidden md:block ${searchModal ? 'z-[100] fixed w-screen h-screen top-0 left-0 right-0' : 'z-0 w-0 h-0'}`}><SearchOverlay visibility={searchModal} setVisibility={setSearchModal}/></div>}
      <div className="relative h-[auto]">
      <div className=" container_maxWidth_1440 shadow-[0_2px_2px_0px_rgba(175,196,12,0.08)] px-[60px] pt-[15px]  fixed top-0 left-0 bg-white z-[100] w-full block md:hidden">
        <div className="w-full" onMouseEnter={()=>    setIsProductDivVisible(false)}>
          <div className="flex justify-between px-[2rem]">
            <Link to="/">
              <div className="logo ml-[-2.1vw]">
                <img src={Logo} alt="Logo" className="w-[150px] h-[60px] " />
              </div>
            </Link>

            <div className="flex items-center justify-between w-2/3 mr-[-2vw]">
              <div className="flex items-center py-[10px] h-[37px] px-[24px] border rounded-full w-full lg:w-[250px] lg:min-w-[250px] border-grayMid">
                <input
                  placeholder="Search Products..."
                  className="w-full focus:outline-none"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  value={search}
                />
                <div onClick={handleSearch} className=""><FiSearch className="text-2xl text-custom-text-grey cursor-pointer md:w-1/2 w-full"/></div>
              </div>
              <div className="flex">
                <div
                  className={`text-custom-text flex justify-end items-center min-w-[260px] lg:min-w-[0px] px-[20px] transition-all ${bulk ? "hidden" : ""
                    } font-semibold`}
                  onMouseOver={() => setBulk(true)}
                >
                  <BsTelephone className="mr-2 lg:mr-0 lg:w-6 lg:h-6" />
                  <span className="lg:hidden ">Contact us for Bulk Order</span>
                </div>
                <div
                  className={`text-custom-text flex justify-end items-center min-w-[260px] px-[20px] transition-all ${bulk ? "" : "hidden"
                    } font-semibold`}
                  onMouseLeave={() => setBulk(false)}
                >
                  <BsTelephone className="mr-2" />
                  <span>
                  <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                  </span>
                </div>
                {Token || RegToken ? (
                  <button className="border-blue text-[15px] lg:text-[40px] mr-3 border-[1px] lg:border-[2px] rounded-[4px] text-custom-text w-[170px] max-w-[170px] lg:flex lg:flex-row lg:justify-center lg:pl-2 lg:w-fit flex items-center pl-3 lg:pr-2 lg:mr-0 lg:rounded-full  h-[40px] font-medium " onClick={handlemyaccount}>
                    <div className=" lg:hidden">My Account{" "}</div>
                    <BsPerson className="border-blue w-4 h-4 lg:ml-0 lg:w-6 lg:h-6  ml-4" />
                  </button>
                ) : (



            
                  <button
                    className="bg-custom-btn hover:bg-custom-btn-dark text-white w-[95px] mr-[12px] h-[39px] rounded transition duration-300"
                    onClick={handleLoginClick}
                  >
                    Log in
                  </button>
                )}
                
                <div className="text-custom-text-green font-semibold cursor-pointer">
                <Link to="/account/cart">
                  <img src={Cart} className="w-9 h-7 mt-1 cursor-pointer object-contain" />
                  </Link>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%]">
      <ul className="flex justify-between items-center mt-5  pl-[1.5vw] pr-[1.5vw] relative">
        <div className=" pt-2 pb-2 pr-2 border-gray-400">
          <li        onMouseEnter={() => handleMouseEnter("Product")}>
            <Link className="text-[#2F2F2F] text-center pt-4 pb-2 px-3 hover:border-b-2 border-blue">
              Products
            </Link>
          </li>
        </div>
        <li onMouseEnter={() => handleMouseEnter("Industry")}>
           <Link className="text-[#2F2F2F] text-center pt-4 pb-2 px-3 hover:border-b-2 border-blue">
            Industry
           </Link>
        </li>

        {parentcategory?.map((header) => (
          <li
            key={header.category_name}
            onMouseEnter={() => handleMouseEnter(header?.parent_category_name,header?.id)}
            className="relative"
          >
            <Link

              className="text-[#2F2F2F] pb-2 hover:border-b-2 border-blue"
            >
              {header?.parent_category_name}
            </Link>
          </li>
        ))}
      </ul>


    </div>

      </div>
      {isProductDivVisible && (
  <div
    className="w-[100%] container_maxWidth_1440 h-[auto] fixed z-[98] top-[135px] pl-[80px] pb-[40px] pr-[50px] products bg-white drop_shadow_2"
    onMouseEnter={() => setIsProductDivVisible(true)}
    style={{ top: "119px", paddingTop: "25px", paddingBottom: "20px" }}
    onMouseLeave={handleMouseLeave}
  >
    {hoveredCategory === "Product" && (
      <div className="all category">
        <h1 className="text-[24px] text-[#2f2f2f] font-[500]">Category</h1>
        <div className="grid grid-cols-7 gap-6 mt-3 max-h-[300px] overflow-auto"> 
          {productCategory ? (
            productCategory?.map((header) => (
              <p
                key={header?.category_name}
                onClick={() => handleNavigateproduct(header?.category_name)}
                to={`/allproducts/${header?.category_name?.split(' ')?.join('')?.toLowerCase()}`}
                className="text-[#2F2F2F] pb-2  hover:underline hover:text-decoration-color hover:decoration-blue-500  hover:decoration-blue hover:decoration-1.5 hover:underline-offset-4 cursor-pointer"
              >
                {header?.category_name}
              </p>
            ))
          ) : (
            ''
          )}
        </div>
      </div>
    )}

    {/* Industry and other categories */}
    {hoveredCategory === "Industry" && (
      <div className="industry">
        <div className="flex flex-col gap-1 w-[14%]">
          <h1 className="text-[24px] text-[#2f2f2f] font-[500] mb-2">Industries</h1>
          {industryloading ? (
            <p>Loading.......</p>
          ) : (
            industrydata1?.industry_categories?.map((item) => (
              <p
                key={item?.industry_category_name}
                className="text-[#2F2F2F] pb-2 hover:border-b-2 cursor-pointer border-blue"
                onClick={() => handleindustrynavigate(item?.industry_category_name)}
              >
                {item?.industry_category_name}
              </p>
            ))
          )}
        </div>
      </div>
    )}

    {/* Other categories */}
    {hoveredCategory && hoveredCategory !== "Industry" && hoveredCategory !== "Product" && (
      <div className="w-full">
        <p className="text-[24px] text-[#2f2f2f] font-[500] mb-2">{hoveredCategory}</p>
        <div className="grid grid-cols-7 gap-4">
  {parentcategorys?.sub_categories?.map((item) => (
    <p
      key={item?.category_name}
      className="text-[#2F2F2F] cursor-pointer hover:underline hover:text-decoration-color hover:decoration-blue-500  hover:decoration-blue hover:decoration-1.5 hover:underline-offset-4"
      onClick={() => handlecategorynavigate(item.category_name)}
    >
      {item?.category_name}
    </p>
  ))}
  {parentcategorys?.sub_categories?.length === 0 && (
    <p className="text-gray-400 font-[400]">No Category Found</p>
  )}
</div>
      </div>
    )}
  </div>
)}

      </div>


      <div className="hidden md:block mx-[12px] sticky top-0 left-0 z-[60]">
  <div className="flex justify-between items-center bg-white">
    <div className="flex">
      <Link to="/">
        <img src={Logo} alt="Logo" className="w-[128px]" />
      </Link>
      <button onClick={() => onOpen()}>
        <RxTextAlignJustify className="text-custom-text-darkGrey w-[30px] h-[30px] mt-1 ml-2" />
      </button>
      <Drawer onClose={onClose} isOpen={isOpen} size="full" placement="left">
        <DrawerOverlay className="bg-white max-h-[100vh] z-[100] w-[100vw]">
          <DrawerContent className="flex flex-col px-[12px] w-[100vw] h-full overflow-y-auto">
            <div className="flex items-center justify-between">
              <img src={Logo} alt="Logo" className="w-[128px]" />
              <DrawerCloseButton className="text-[13px]" />
            </div>
            <div className="bg-custom-btn text-white rounded-[4px] mb-[24px]">
              <p className="flex items-center justify-center text-[10px]">
                <BsTelephone className="mr-2" />
                Contact us for Bulk Order{" "}
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              </p>
            </div>
            <div className="overflow-y-auto">
              <MobileCategoryAccordian onClose={onClose} />
            </div>
            <hr className="border-[#D9D9D9] border-t-2" />
            {Token || RegToken ? (
              <div>
                <button
                  className="border-blue mt-2 text-[15px] mr-3 border-[1px] rounded-[4px] text-custom-text w-[150px] flex items-center pl-3 h-[40px] font-medium"
                  onClick={handlemyaccount}
                >
                  My Account
                  <BsPerson className="border-blue w-4 h-4 ml-4" />
                </button>
              </div>
            ) : (
              <div className="flex justify-between items-center text-[14px] px-[12px] py-[20px] mb-[12px] text-custom-text-darkGrey font-semibold">
                <button onClick={() => { handleLoginClick(); onClose(); }}>Login</button>
                <BsArrowRight />
              </div>
            )}
            {/* <div className="relative px-[12px] py-[18px] h-[180px] mt-[70px]">
              <img
                src={navImg}
                alt="navbar shopnow img"
                className="absolute top-0 left-0 w-full h-full"
              />
              <div className="text-[12px] text-white z-10 absolute h-full">
                <h5 className="font-semibold mb-[12px]">Create Your Unique Custom Box</h5>
                <p>
                  Tailor-Made Packaging
                  <br /> Solutions for Your Brand
                </p>
                <button onClick={() => handleShop()} className="flex items-center justify-between w-[5rem] px-[8px] py-[4px] bg-custom-btn text-[10px] rounded mt-[30px]">
                  Shop Now <BsArrowUpRight />
                </button>
              </div>
            </div> */}
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </div>
    <div className="flex items-center justify-between pr-2">
      <img
        src={SearchBtn}
        className="w-[25px] h-[25px] mr-5"
        alt="searchbtn"
        onClick={() => setSearchModal(!searchModal)}
      />
      <Link to="/account/cart">
        <img src={CartBtn} className="w-[25px] h-[25px]" alt="cartbtn" />
      </Link>
    </div>
  </div>
  <div className="bg-custom-btn p-1 text-white rounded-[4px]">
    <p className="flex items-center justify-center text-[10px]">
      <BsTelephone className="mr-2" />
      Contact us for Bulk Order{" "}
      <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
    </p>
  </div>
</div>


      {showLogin && (
        <div ref={modalRef}>
          <Login setShowLogin={setShowLogin} />
        </div>
      )}
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />
    </>
  );
};

export default Navbar;