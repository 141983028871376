import { useEffect } from "react";
import PageTopNavigation from "../components/PageTopNavigation";
import { Link } from "react-router-dom";
import productImg from "../assets/img/Banner.png";
import MobileAbout from "../components/about/MobileAbout";
import { useGetallaboutusQuery } from "../redux/faqrtk/FaqRtk";
import About1 from '../assets/img/about_us_1.jpg'
import About2 from '../assets/img/about_us_2.jpg'
import About3 from '../assets/img/about_us_3.jpg'


function About() {
    const {data,isLoading} = useGetallaboutusQuery()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    
    return (

        <div className="md:mx-[12px] container_maxWidth_1440  font-lato pl-4 pr-4 md:pl-0 md:pr-0 md:mt-[0px] mt-[50px] mb-24 text-custom-text-p text-[#2F2F2F]">
          <div className="mt-[70px] ml-3 md:mt-0">
        <PageTopNavigation firstRouteLink={'/'} firstRouteName={'Home'} secondRouteName={'AboutUs'}  />
        </div>
            {/* First Paragraph */}
             <h1 className="text-3xl pl-3 text-black font-medium md:hidden">About Us</h1>

            <div className="md:hidden block">
            <div className="w-[100%]">
            {isLoading?(
                <>
                    <p>Loading...</p>
                </>
            ):(
                data?.results?.map((item,index)=>(
                    <>
                    <div className={`flex justify-between ${(index + 1) % 2 === 0 ? 'flex-row-reverse' : ''}  pl-3 mt-5 md:block w-[100%] `}>
                        <div className="text w-[55%] mt-8">
                            <p className="text-black pt-2 text-[18px] font-semibold">{item?.title}</p><br/>
                            <p className="text-[18px] font-normal leading-[21.6px]">{item?.subject}</p>
                        </div>
                        <div className="image">
                            <img src={item?.image?item?.image:About1} className="w-[450px] h-[300px] bg-[#D9D9D9] rounded-lg" />
                        </div>
                </div>
                <div className="w-[100%] flex justify-between items-center mt-[40px] md:block">
                {item?.images?.length>=2? (item?.images?.map((image,index)=>(
                    <div className="image">
                            <img src={image?.img?image?.img:About1} className="w-[350px] ml-1 h-[300px] rounded-lg bg-[#D9D9D9]" />
                        </div>

                ))

           ):""

                }
                </div>
                    </>
                ))
            )

            }
            
            </div>

{/* 
            <div className="w-[100%] mt-[30px]">
            <h1 className="text-[18px] text-[#2F2F2F] font-semibold">Customization and Consultation: Tailored Solutions for Your Unique Needs</h1>
            <h2 className="text-[18px] pt-1 text-[#2F2F2F] font-normal leading-[21.6px]">Every business is unique, and we recognize that. InkPrint offers personalized packages and expert consultations to align our services with your specific requirements. Our dedicated team collaborates closely with you to ensure that your printing solutions are tailored to suit your business preferences, goals, and budget</h2>
            </div>

            <div className="w-[100%] mt-[20px]">
            <h1 className="text-[18px] text-[#2F2F2F] font-semibold">Get Started Today: Empowering Your Print Journey</h1>
            <h2 className="text-[18px] pt-1 text-[#2F2F2F] font-normal leading-[21.6px]">The journey to exceptional printing and packaging starts here. InkPrint empowers you to transform your ideas into reality. Reach out to us today to embark on a print journey that combines creativity, innovation, and unwavering commitment to quality. Let's work together to make your vision come to life.</h2>
            </div> */}


          
            </div>


            {/* this for responsive */}
            {isLoading?(
                <>
                    <p>Loading...</p>
                </>
            ):(
                data?.results?.map((item,index)=>(
                    <MobileAbout title={item?.title} data={item?.subject} image={item?.image} images={item?.images}/>
                ))
            )

            }


            {/* <div className="hidden md:block mb-3">
            <div className="">
            <h1 className='text-black pt-2 text-[12px] font-semibold'>Get Started Today: Empowering Your Print Journey</h1>
            <p className='text-[12px] font-normal mt-1 leading-[21.6px]'>The journey to exceptional printing and packaging starts here. InkPrint empowers you to transform your ideas into reality. Reach out to us today to embark on a print journey that combines creativity, innovation, and unwavering commitment to quality. Let's work together to make your vision come to life.</p>
            <div className="flex justify-between items-center mt-2 ">
            <img src={About1} className="w-[30vw] h-[15vh] "></img>
            <img src={About2} className="w-[30vw] h-[15vh] "></img>
            <img src={About3} className="w-[30vw] h-[15vh] "></img>
            </div>
            </div>
            </div> */}





            {/* <h4 className="font-medium mt-[22px] mb-[12px] md:text-[12px] text-[18px] md:mb-[8px] md:mt-[12px]">Contact Us</h4>
           <p>By E-mail</p>
           <p className="text-black font-semibold">contact@inkprint.in</p>
           <p>By Phone:<span className="text-black font-semibold">+91-928-944-9945 (India)</span></p>
           <p className="text-black font-semibold">Open 9AM-6--PM from Monday to Friday</p>
           <p>Address:<span className="text-black font-semibold"> Inkprint.in Turtle Media Pvt. Ltd.,9 DSIDC Sheds, IInd Floor, </span></p>
           <p className="text-black font-semibold">Okhla Industrial Area Phase I,</p>
           <p className="text-black font-semibold">New Delhi – 110020,</p>
           <p className="text-black font-semibold">India</p>
           <p>Contact us on Whatsapp: <span className="text-black font-semibold">+91-928-944-9945</span></p>
           <p>We look forward to hearing from you and helping you achieve exceptional printing and packaging solutions.</p>
              
            <Link to="/contactus">
                <span className="text-custom-text md:text-[12px] text-lg cursor-pointer">Your success is our priority!</span>
            </Link> */}
        </div>
    )
}

export default About;