import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";
import ProductImage from "../../components/Product/ProductImage";
import ProductCustomisation from "../../components/Product/ProductCustomisation";
import { useGetAllIndividualProductQuery, useProductMetaTagsQuery, useProductReviewDetailQuery, useProductSpecificationsQuery } from "../../redux/individualProduct/individualProduct";
import ProductRelatedInfo from "../../components/Product/ProductRelatedInfo";
import { useEffect } from "react";
import ProductReview from "../../components/Product/ProductReview";
import ProductDescription from "../../components/Product/ProductDescription";
import SubProductDetails from "../../components/Product/SubProductDetails";
import ProductSpecification from "../../components/Product/ProductSpecification";
import ProductFAQ from "../../components/Product/ProductFAQ";
import '../../App.css'
import RelatedProducts from "../../components/Product/RelatedProducts";
import BuyTogether from "../../components/Product/BuyTogether";
import { Helmet } from "react-helmet";

function Product() {
    const location = useLocation();
    const locationArray = location.pathname.split('/').slice(1).slice(0, -1);
    
    const {id} = useParams();
    console.log(id)
    
    const {data, isLoading} = useGetAllIndividualProductQuery(id);
    const {data:specData} = useProductSpecificationsQuery(id);
    const {data:metaData} = useProductMetaTagsQuery(parseInt(id));
    const {data:ReviewData} = useProductReviewDetailQuery(id)

    console.log(data)


    // console.log("this is product data",data)


    console.log("this si data?.product",data?.product)


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    return (
        <div className="container_maxWidth_1440">
            <Helmet>
                <title>{metaData?.meta_tags?.title}</title>
                <meta name="description" content={`${metaData?.meta_tags?.description}`}/>
                <meta name='keywords' content={`${metaData?.meta_tags?.keywords}`}/>
                <meta name="og:title" content={`${metaData?.meta_tags?.og_title}`}/>
                <meta name="og:description" content={`${metaData?.meta_tags?.og_description}`}/>
            </Helmet>
            {
                isLoading ? 
                <div className="w-screen h-screen flex justify-center items-center"><div className="custom-loader"></div></div> :

                <div className=" mt-[195px] md:mt-5 mx-[60px] md:mx-[20px] flex flex-col  gap-y-[30px] md:gap-y-1 ">
                    <div className="flex items-center mb-[40px] md:mb-2 flex-nowrap md:flex-wrap">
                        <Link to="/">Home</Link>
                        <IoIosArrowForward className="text-[#949494] mx-[16px]"/>
                        {
                            locationArray.map((route, index) => {
                                return (
                                    <div className="flex items-center" key={route}>
                                        <Link to={route === 'allproducts' ? `/allproducts` : ""}>{
                                            route==="allproducts"?"All Products":route !== 'product' && route[0].toUpperCase() + route.slice(1)
                                        }</Link>
                                        {route !== 'product' && <IoIosArrowForward  className="text-[#949494] mx-[16px]"/>}
                                    </div>
                                )
                            })
                        }
                        <Link to={`/allproducts/${data?.product?.category?.category_name?.split(' ')?.join('')?.toLowerCase()}`}>{data?.product?.category?.category_name}</Link>
                        <IoIosArrowForward  className="text-[#949494] mx-[16px]"/>
                        <div className="text-[16px] font-medium text-[#A7C539]">{data?.product?.title}</div>
                    </div>

                    <div className="flex w-full gap-x-24 mx-auto md:hidden relative">
                        <div className="w-[40%] pt-3"><ProductImage imageData={data?.product}/></div>
                        <div className="w-1/2"><ProductCustomisation productData={data?.product}/></div>
                    </div>

                    <div className="hidden md:flex md:flex-col md:gap-y-6">
                        <div><ProductImage imageData={data?.product}/></div>
                        <div><ProductCustomisation productData={data?.product}/></div>
                        {/* <div className="sticky bottom-0 text-2xl text-blue-500">Add to cart</div> */}
                    </div>

                    {/* <div className="flex w-full mx-auto">
                        <div className="w-[47%] md:w-0"></div>
                        <RelatedProducts productData={data?.product}/>
                    </div> */}

                    <div className="w-full">
                        <SubProductDetails subProductData={data?.sub_product_details}/>
                    </div>

                    <div className="flex flex-col md:hidden">
                        {
                            data?.items.map((info, index) => (
                                <ProductRelatedInfo info={info} pos={index> 0 ? "flex-row-reverse" : "flex-row"}/>
                            ))
                        }
                    </div>

                    <div className="hidden md:flex">
                        {/* <ProductRelatedInfo info={data?.items[0]}/> */}
                    </div>

                    <div>
                        {/* <ProductReview ratingData={ReviewData}/> */}
                    </div>

                    <div className="w-full flex flex-col md:flex-col-reverse gap-y-[60px]">
                        {
                            data?.descriptions.map((descData) => (
                                <ProductDescription descData={descData}/>
                            ))
                        }
                    </div>
                     
                    <div className="w-full mt-[0px]">
                        {specData?.length > 0 && <ProductSpecification specData={specData}/>}
                    </div>

                    <div className="flex flex-col gap-y-9 mt-[50px]">
                        {/* <div className="text-[32px] font-medium">Customers who bought this item also bought</div> */}
                        {data?.product?.length>0 &&
                            <BuyTogether productData={data?.product}/>
                        }
                       
                    </div>

                    <div>
                        <ProductFAQ/>
                    </div>
                </div>
            }
        </div>
    )
}

export default Product;