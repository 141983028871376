import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL
const token = localStorage.getItem("token")
export const MyAccount = createApi({
    reducerPath: "MyAccount",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers) => {
            if (token) {
              headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
          },
    }),
    tagTypes:["Wishlist"],
    endpoints: (builder) => ({
        UserDetail: builder.query({
            query: () => "/api/user/",
        }),
        UserOrders: builder.query({
            query: () => "/api/productsheet/list-orders/",
        }),
        UserWhish:builder.query({
            query:() => ({
                url :"/api/productsheet/view-wishlist/",
            }),
            providesTags:["Wishlist"],
        }),
        UserAddWish: builder.mutation({
            query:(data)=> ({
                url:'/api/productsheet/wish-list-save/',
                method:'POST',
                body:data,
            }),
            invalidatesTags:["Wishlist"]
        }),
        UserRemoveWish: builder.mutation({
            query:(id) => ({
                url:`/api/productsheet/delete-wishlist-item/${id}`,
                method:'DELETE',
            }),
            // invalidatesTags:['Wishlist'],
        }),
        UserEdit:builder.mutation({
            query:(data)=>({
                url:'/api/user/update/',
                method:'PATCH',
                body:data
            })
        }),
        MoveToWish:builder.mutation({
            query:(data)=>({
                url:`/api/productsheet/move-to-wishlist/${data}/`,
                method:'POST'
            }),
            invalidatesTags:['Wishlist']
        }),
        WishQuantityUpdate:builder.mutation({
            query:(data)=>({
                url:'/api/productsheet/quantity-change/',
                method:'POST',
                body:data
            }),
        }),
        UndoWishList:builder.mutation({
            query:(id)=>({
                url:`/api/productsheet/undo-wishlist-item/${id}/`,
                method:'POST'
            }),
            invalidatesTags:['Wishlist']
        }),
        SubmitReviewOrder:builder.mutation({
            query:(formdata)=>({
                url:"/api/productsheet/product-review/",
                method:"POST",
                body:formdata
            })
        }),
        UserDesign: builder.query({
            query: () => "/api/productsheet/get-my-designs/",
        }),
    }),
});

export const { useLazyUserDetailQuery, useUserOrdersQuery,useUserWhishQuery, useUserAddWishMutation, useUserRemoveWishMutation, useUserEditMutation,useMoveToWishMutation,useWishQuantityUpdateMutation,useUndoWishListMutation,useSubmitReviewOrderMutation,useUserDesignQuery} = MyAccount;
