import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const Private = createApi({
    reducerPath: "Private",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_BASE_URL, 
    }),
    endpoints: (builder) => ({
        getAllReturn: builder.query({
            query: () => "/api/returnPolicy/get-all/",
        }),
        getAllPrivate: builder.query({
            query: () => "/api/privatePolicy/get-all/",
        }),
    }),
});

export const { useGetAllReturnQuery, useGetAllPrivateQuery } = Private;
