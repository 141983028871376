import React, { useLayoutEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';


const GooglePage = () => {
    const location = useLocation();
    const navigate = useNavigate()
    useLayoutEffect(() => {
        const getQueryParams = () => {
          return new URLSearchParams(location.search);
        };
    
        const queryParams = getQueryParams();
        const accessToken = queryParams.get('access_token');
        
        if (accessToken) {
          localStorage.setItem("token", accessToken);
          navigate("/", { replace: true }); 
          window.location.reload(); 
        }
      }, [location, navigate]);
  return (
    <>
    <div className='w-[100%] h-[500px] bg-white'>
        
    </div>

    </>
  )
}

export default GooglePage