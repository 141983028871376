import { Link } from "react-router-dom";
import Next from '../assets/img/icons/next.svg'

function PageTopNavigation({firstRouteLink, firstRouteName, secondRouteName,secondRouteLink,thirdRouteName,second,thirdRouteLink,thirdRoutename,fourthRoutename,third,secondcolor}) {
    return (
       <div className="w-[60vw]">
         <div className="md:mt-[6px] flex items-center md:mb-[10px] py-[4px] mt-[196px] mb-[60px] md:text-[10px] text-sm">
            <Link to={firstRouteLink}><span className="text-[#2f2f2f] font-normal text-[16px] leading-normal mr-[5px] md:pt-[-2px]">{firstRouteName}</span></Link>
            <span className="text-custom-text-grey mr-1"><img src={Next} className="w-[24px] h-[24px] md:w-[12px] md:h-[12px]"/></span>
            {second===true?'':<span className={`text-[#A7C539] font-medium text-[16px]`}>{secondRouteName}</span>}
            {second && 
                <>
                <Link to={secondRouteLink}><span className={`text-[#2f2f2f] ${secondcolor?`text-[${secondcolor}]`:'text-[#2f2f2f]'} font-normal text-[16px] leading-normal mr-[5px] md:pt-[-2px]`}>{secondRouteName}</span></Link>
            <span className="text-custom-text-grey mr-1"><img src={Next} className="w-[24px] h-[24px] md:w-[12px] md:h-[12px]"/></span>
            <span className="text-[#A7C539] font-medium text-[16px]">{thirdRouteName}</span>
                </>

            }
            {third &&
            <>
            <Link to={thirdRouteLink}><span className="text-[#2f2f2f] font-normal text-[16px] leading-normal mr-[5px] md:pt-[-2px]">{thirdRoutename}</span></Link>
            <span className="text-custom-text-grey mr-1"><img src={Next} className="w-[24px] h-[24px] md:w-[12px] md:h-[12px]"/></span>
            <span className="text-[#A7C539] font-medium text-[16px]">{fourthRoutename}</span>
            </>

            }
        </div>
       </div>
    )
}

export default PageTopNavigation;