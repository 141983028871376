import { useEffect, useState } from 'react';
import btnPrev from '../../assets/img/btnPrev.png';
import btnNext from '../../assets/img/btnNext.png';

import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaPinterestSquare } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
import { Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

function ProductImage({imageData}) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const imagePath = imageData?.images;
        const arr = imagePath?.map(url => url.img);
        arr?.unshift(imageData?.image);
        setData(arr);
        setLoading(false);
    }, [imageData]);

    function rightRotateArray() {
        const newArray = [...data];
        const lastElement = newArray.pop();
        newArray.unshift(lastElement);
        setData(newArray);
    }

    function leftRotateArray() {
        const newArray = [...data];
        const firstElement = newArray?.shift();
        newArray?.push(firstElement);
        setData(newArray);
    }

    const firstEle = data?.length ? data[0] : "";

    // Construct Pinterest share URL
    const pinterestUrl = `https://in.pinterest.com/pin-builder/?description=${encodeURIComponent('Custom packaging boxes')}&media=${encodeURIComponent(firstEle || imageData?.image)}&method=button&url=${encodeURIComponent(window.location.href)}`;

    return (
        <div className='w-full sticky top-24'>
            {
                loading ? <div>Loading</div> : 
                <div className="w-full">
                    <div className='w-full relative'>
                        <img src={firstEle} className='w-full' alt={imageData?.image}/>
                        <button onClick={rightRotateArray}><img src={btnPrev} alt="Previous" className='w-[32px] h-[32px] absolute top-[50%] left-0'/></button>
                        <button onClick={leftRotateArray}><img src={btnNext} alt="Next" className='w-[32px] h-[32px] absolute top-[50%] right-0'/></button>
                    </div>
                    
                    <div className='flex my-[16px]'>
                        {imageData?.sub_data?.images?.map((i, index) => (
                            <img key={index} src={i?.img} className='w-[160px] h-[160px] md:w-[50px] md:h-[50px] mr-[8px]' alt={`Product thumbnail ${index + 1}`}/>
                        ))}
                    </div>
                
                    <div className='flex flex-nowrap md:flex-wrap justify-between items-end w-full md:gap-y-[12px]'>
                        <div className='flex flex-col justify-between'>
                            <p>SKU: INK_CustomProductBoxes_13856</p>
                            <p>Category: Box Packaging</p>
                        </div>
                    
                        <div className='flex text-[24px] text-[#949494]'>
                            <Tooltip label="Facebook" className="bg-black text-white text-[12px] px-[8px] py-[5px] rounded-lg">
                                <Link to="https://www.facebook.com/inkprintdotin/">
                                    <button className='transition duration-700 hover:text-black mr-[9px]'><FaFacebookSquare/></button>
                                </Link>
                            </Tooltip>

                            <Tooltip label="Twitter" className="bg-black text-white text-[12px] px-[8px] py-[5px] rounded-lg">
                                <Link to="https://x.com/inkprintindia">
                                    <button className='transition duration-700 hover:text-black mr-[9px]'><FaTwitterSquare/></button>
                                </Link>
                            </Tooltip>

                            <Tooltip label="Pinterest" className="bg-black text-white text-[12px] px-[8px] py-[5px] rounded-lg">
                                <a href={pinterestUrl} target="_blank" rel="noopener noreferrer">
                                    <button className='transition duration-700 hover:text-black mr-[9px]'>
                                        <FaPinterestSquare/>
                                    </button>
                                </a>
                            </Tooltip>

                            <Tooltip label="Linkedin" className="bg-black text-white text-[12px] px-[8px] py-[5px] rounded-lg">
                                <Link to="https://www.linkedin.com/company/turtle-media/">
                                    <button className='transition duration-700 hover:text-black mr-[9px]'><BsLinkedin/></button>
                                </Link>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default ProductImage;
