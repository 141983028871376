import axios from 'axios'
import React, { useState } from 'react'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import { ImCross } from 'react-icons/im'
import { Link } from 'react-router-dom'

const ForgotS = ({token}) => {
    const [password1,setPassword1]=useState()
    const [password2,setPassword2]=useState()
    const [passwordToggle2, setPasswordToggle2] = useState(true);
    const [passwordToggle3, setPasswordToggle3] = useState(true);
    const [loading,setLoading]=useState(false)
    const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL
    const [success,setSuccess]=useState(false)
    const handlePasswordToggle2 = () => {
        setPasswordToggle2(!passwordToggle2);
      };const handlePasswordToggle3 = () => {
        setPasswordToggle3(!passwordToggle3);
      };
      const handlesubmit2 = async ()=>{
        setLoading(true)
        if(password1===""||password2===""){
          setLoading(false)
            return alert("please fill all detail")
        }
        const body={
            password:password1,
            password2:password2
        }

        try{
            const response = await axios.post(`${BASE_URL}/api/user/password/reset/${token}`,body)
            const res = await response.data
            alert(res?.msg)
            setSuccess(true)
            setLoading(false)
        }catch(error){
            console.log()
            setLoading(false)
        }

      
      }

  return (
   <>
     <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
       {success===true? 
       <>
       <div className='w-[816px] h-[405px] bg-white rounded-[24px]'>
       <div className='flex justify-end mr-5'>
       <Link to="/">
       <ImCross className="w-3 h-3 cursor-pointer mt-[20px] text-[#ADADAD]  " />
       </Link>
         </div>
         <h1 className="text-2xl font-[500] font-lato text-center mb-4 pt-10 text-[#2f2f2f]">Password changed</h1>
         <div className='flex justify-center items-center'>
         <div className='w-[370px] pt-[80px]'>
            <p className='text-center'>Your password has been successfully changed</p>
            <button className='w-[100%]  bg-custom-btn text-white rounded-[4px] h-[39px] mt-10' onClick={handlesubmit2}>login</button>
         </div>
         </div>

       </div>
       
       </>:<> <div className='w-[700px] h-[592px] bg-white rounded-[24px]'>
        <div className='flex justify-end mr-5'>
       <Link to="/">
       <ImCross className="w-3 h-3 cursor-pointer mt-[20px] text-[#ADADAD]  " />
       </Link>
         </div>
          <div className="login_container">
            <h1 className="text-2xl font-[500] font-lato text-center mb-4 pt-10 text-[#2f2f2f]">Reset Password</h1>
          </div>
          <div className='flex items-end justify-center h-[50%]' >
           <div className='w-[370px]'>
           <div className='mt-4'>
            <label htmlFor='currentPassword' className='text-custom-text-grey cursor-pointer'>
              New Password <span className='text-red-500'>*</span>
            </label>
            <div className='border-[1.7px] border-[#DDDDDE] pr-1  w-[100%] pl-2 rounded-[4px] flex'>
              <input
                type={passwordToggle2 ? 'password' : 'text'}
                id='currentPassword'
                placeholder='New Password'
                className='pl-2 w-[97%] outline-none pt-1 pb-1'
                value={password1}
                name='password'
              onChange={(e)=>setPassword1(e.target.value)}
              />
              <button type='button' onClick={handlePasswordToggle2}>
                {passwordToggle2 ? <BsFillEyeSlashFill className='md:text-[12px]' /> : <BsFillEyeFill className='md:text-[12px]' />}
              </button>
            </div>
          </div>
          {/* ------- */}
          <div className='mt-4'>
            <label htmlFor='currentPassword' className='text-custom-text-grey cursor-pointer'>
              Comfirm Password <span className='text-red-500'>*</span>
            </label>
            <div className='border-[1.7px] border-[#DDDDDE] pr-1 w-[100%] pl-2 rounded-[4px] flex'>
              <input
                type={passwordToggle3 ? 'password' : 'text'}
                id='currentPassword'
                placeholder='Comfirm Password'
                className='pl-2 w-[97%] outline-none pt-1 pb-1'
                value={password2}
                name='password2'
                onChange={(e)=>setPassword2(e.target.value)}
              />
              <button type='button' onClick={handlePasswordToggle3}>
                {passwordToggle3 ? <BsFillEyeSlashFill className='md:text-[12px]' /> : <BsFillEyeFill className='md:text-[12px]' />}
              </button>
            </div>
          </div>

          {/* -------------- */}
          <button className='w-[100%] bg-custom-btn text-white rounded-[4px] h-[39px] mt-5' onClick={handlesubmit2}>{loading===true?'Loading....':'Reset password'}</button>
           </div>
          </div>
        </div></>
        
       }
      </div>
   </>
  )
}

export default ForgotS