import { useState } from "react";
import { IoMail } from "react-icons/io5";
import { FaLock } from "react-icons/fa";
import { BsFillEyeFill, BsFillEyeSlashFill, BsFillPersonFill } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import AuthComponentBtn from "./AuthComponentBtn";
import GoogleAuthButton from "./GoogleAuthButton";
import { Link, useNavigate } from "react-router-dom";
import { useUserLoginMutation, useUserRegisterMutation } from '../redux/authenticationrtk/UsersAuthApi'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MyContext } from "../context/Forgot";
import { useContext } from "react";
import { ImCross } from "react-icons/im";


function Loginform({ setLogForm, logForm, handleCloseModal ,setForgot,forgot}) {
  const [passwordToggle, setpasswordToggle] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isloading,setIsloading]=useState(false)
  const {setValue} = useContext(MyContext)
  

  const [LoginApi, { mutate: UserLogin, isLoading: isLoginLoading }] = useUserLoginMutation();
  const handleLogin = async () => {
    setIsloading(true)
    try {
      if(email==="" || password===""){
        setIsloading(false)
        return  toast.error('Please fill all details', {
          position: 'top-center',
          autoClose: 3000,
        });
      }
      const userLoginData = {
        email: email,
        password: password,
      };
      const response = await LoginApi(userLoginData);
      const token = response.data.access;
      localStorage.setItem("token", token);
      setIsloading(false)
      toast.success('Login successful', {
        position: 'top-center',
        autoClose: 3000,
      });
      console.log("Login response:", response);
      setTimeout(function() {
        window.location.reload();
    }, 2000);
    } catch (error) {
      setIsloading(false)
      toast.error('Login failed. Please check your credentials.', {
        position: 'top-center',
        autoClose: 3000,
      })
    }
  };
  const handleForgot = ()=>{
    setValue(true)
    handleCloseModal()
  }
  return (
    <div className={`h-full w-full flex justify-center items-center md:flex-col `}>
      <span onClick={handleCloseModal} className="absolute top-[40px] left-0 text-custom-text hover:underline hidden md:flex items-center"><IoIosArrowBack /> Back</span>
      <div className={`w-[408px] md:w-[92%] md:border md:border-blue md:rounded-[12px] md:mx-[16px] md:p-[24px]`}>
        <h4 className="font-medium text-[36px] mb-[7px] md:text-[24px] md:font-normal md:mb-[16px]">Log In</h4>
        <p className="text-custom-text md:font-bold md:text-[14px]">Elevate Your Brand with Premium Printing Solutions</p>
        <div className="flex items-center w-full border-grayMid border rounded-lg text-custom-text-grey px-[12px] py-[6px] md:py-[10px] mt-[24px] mb-[10px]">
          <IoMail className="md:text-[17px]"/>
          <input type="text" name="email" placeholder="Username or Email Address" className="text-black focus:outline-none px-[12px] w-full md:text-[14px]" onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="flex items-center w-full border-grayMid border rounded-lg text-custom-text-grey px-[12px] py-[6px] md:py-[10px] mb-[20px] md:mb-[14px]"> 
          <FaLock className="md:text-[17px]" />
          <input type={passwordToggle ? 'password' : 'text'} name="password" placeholder="Password" className="text-black focus:outline-none px-[12px] w-full md:text-[14px]" onChange={(e) => setPassword(e.target.value)} />
          <button onClick={() => { setpasswordToggle(!passwordToggle) }}>{passwordToggle ? <BsFillEyeSlashFill className="md:text-[12px]"/> : <BsFillEyeFill className="md:text-[12px]"/>}</button>
        </div>
        <div onClick={handleLogin}>
          <button className="w-[100%]" disabled={isloading===true?true:false}><AuthComponentBtn>{isloading===true?<span className="loader mb-2"></span>:'Log in'}</AuthComponentBtn></button>
        </div>
        <p className="font-medium text-[14px] cursor-pointer text-custom-text mt-[15px] md:mt-[10px] md:font-normal md:text-[12px]" onClick={handleForgot}>Forgot Password?</p>
        <div className="flex w-full justify-center items-center mt-[1px] md:my-[24px]">
          <hr className="border-t border-grayLight w-[57px]"/>
          <span className="mx-[10px] text-custom-text-grey">or</span>
          <hr className="border-t border-grayLight w-[57px]"/>
        </div>
        <GoogleAuthButton><span className="font-medium md:text-[12px]">Log in with Google</span></GoogleAuthButton>
        <p className="font-medium text-[14px] text-custom-text-grey mt-[24px] md:text-[14px]">New to InkPrint?<button onClick={() => { setLogForm(!logForm) }} className="text-custom-text ml-[6px]">Register here!</button></p>
      </div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />
 

    </div>
  )
}
function SignupForm({ setLogForm, logForm, handleCloseModal,setForgot,forgot}) {
  const [passwordToggle, setpasswordToggle] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [username, setUsername] = useState("");
  const [isloading,setIsloading]=useState(false)
  const [RegisterApi, { mutate: UserLogin, isLoading: isLoginLoading }] = useUserRegisterMutation();
  const navigate = useNavigate()
  const handleRegister = async () => {
    setIsloading(true)
    if(email==="" || password==="" || password2==="" || username===""){
      setIsloading(false)
      return toast.error('Please fill all details', {
        position: 'top-center',
        autoClose: 3000,
      })
    }
    try {
      if (password.length < 8) {
        setIsloading(false)

        toast.error('Password must be 8 character', {
          position: 'top-center',
          autoClose: 3000,
        })
        return;
      }

      if (password !== password2) {
        setIsloading(false)

        toast.error('Comfirm Password not match', {
          position: 'top-center',
          autoClose: 3000,
        })
        return;
      }
      if (!email.includes('@')) {
        setIsloading(false)

        toast.error('Invalid email address', {
          position: 'top-center',
          autoClose: 3000,
        });
        return;
      }
      const userRegister = {
        email: email,
        password: password,
        password2: password2,
        username: username
      };
      const response = await RegisterApi(userRegister);
      setIsloading(false)

      toast.success('Registration successful', {
        position: 'top-center',
        autoClose: 3000,
      });

      if (response.data) {
        setEmail("");
        setPassword("");
        setPassword2("");
        setUsername("");
        setTimeout(function() {
          setLogForm(!logForm)
      }, 1000);
      }
    } catch (error) {
      setIsloading(false)
      toast.error(error, {
        position: 'top-center',
        autoClose: 3000,
      })
    }
  }
  const handleNavigate = (route) =>{
    handleCloseModal(false)
    navigate(route)
  }
  return (
  
    <div className='h-full w-full flex justify-center items-center'>
       <span onClick={handleCloseModal} className="absolute top-[40px] left-0 text-custom-text hover:underline hidden md:flex items-center"><IoIosArrowBack /> Back</span>
      <div className='w-[408px] md:w-[93%] md:border md:border-blue md:rounded-[12px] md:mx-[16px] md:p-[24px]'>
        <h4 className="font-medium text-[36px] mb-[7px] md:text-[24px] md:font-normal md:mb-[16px]">Sign Up</h4>
        <p className="text-custom-text md:font-bold md:text-[14px]">Elevate Your Brand with Premium Printing Solutions</p>
        <GoogleAuthButton register={true}><span className="font-medium md:text-[12px]">Sign up with Google</span></GoogleAuthButton>
        <div className="flex w-full justify-center items-center mt-[15px] mb-[15px]">
          <hr className="border-t border-grayLight w-[57px]" />
          <span className="mx-[10px] text-custom-text-grey">or</span>
          <hr className="border-t border-grayLight w-[57px]" />
        </div>
        <div className="flex items-center w-full border-grayMid border rounded-lg text-custom-text-grey px-[12px] py-[6px] mt-[10px] mb-[1px]">
          <BsFillPersonFill className="md:text-[17px]"/>
          <input type="text" name="username" placeholder="Username" className="text-black focus:outline-none px-[12px] w-full " onChange={(e) => setUsername(e.target.value)} value={username} />
        </div>
        <div className="flex items-center w-full border-grayMid border rounded-lg text-custom-text-grey px-[12px] py-[6px] mt-[10px] mb-[10px]">
          <IoMail />
          <input type="text" name="email" placeholder="Your Email Address" className="text-black focus:outline-none px-[12px] w-full" onChange={(e) => setEmail(e.target.value)} value={email} />
        </div>
        <div className="flex items-center w-full border-grayMid border rounded-lg text-custom-text-grey px-[12px] py-[6px] mb-[12px]">
          <FaLock className=" w-4 h-4" />
          <input type={passwordToggle ? 'password' : 'text'} name="password" placeholder="Password" className="text-black focus:outline-none px-[12px] w-full" onChange={(e) => setPassword(e.target.value)} value={password} />
          <button onClick={() => { setpasswordToggle(!passwordToggle) }}>{passwordToggle ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}</button>
        </div>
        <div className="flex items-center w-full border-grayMid border rounded-lg text-custom-text-grey px-[12px] py-[6px] mb-[12px]">
          <FaLock className=" w-4 h-4" />
          <input type={passwordToggle ? 'password' : 'text'} name="password" placeholder="Confirm Password" className="text-black focus:outline-none px-[12px] w-full" onChange={(e) => setPassword2(e.target.value)} value={password2} />
          <button onClick={() => { setpasswordToggle(!passwordToggle) }}>{passwordToggle ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}</button>
        </div>
        <p className="text-[12px] md:text-[11px] text-custom-text-grey flex mb-[15px] whitespace-nowrap">
  By signing up, I agree to the
  <span
    className="decoration-solid underline cursor-pointer pl-[2px] pr-[2px]"
    onClick={() => handleNavigate("/termsandconditions")}
  >
    Terms of Service
  </span>
  and
  <span
    className="decoration-solid underline cursor-pointer pl-[2px] pr-[2px]"
    onClick={() => handleNavigate("/privacypolicy")}
  >
    Privacy Policy
  </span>
  .
</p>
        <div onClick={handleRegister}>
          <button className="w-[100%]" disabled={isloading===true?true:false}><AuthComponentBtn>{isloading===true?<span className="loader mb-2"></span>:'Sign up'}</AuthComponentBtn></button>
        </div>
        <p className="font-medium text-[14px] text-custom-text-grey mt-[15px]">Have an account? <button onClick={() => { setLogForm(!logForm) }} className="text-custom-text">Login now!</button></p>
      </div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />
    </div>

  );
}

function ForgotForm({setForgot,forgot,handleCloseModal}){
return(
  <>
      <div className="bg-white w-[700px] p-6 rounded-lg relative ">
  <ImCross onClick={handleCloseModal} className="w-3 h-3 cursor-pointer mt-[20px] text-[#ADADAD]  ml-[600px]" />
  <div className="login_container mt-[170px]  ">
    <h1 className="text-2xl font-bold mb-4 pt-10 pl-[220px]">Forgot Password?</h1>
  </div>
  <div className="login_inputs mt-4">
    <div className="mb-4 w-[370px] ml-[150px] p-2">
     <h1 className='font-semibold text-[#474747] text-sm pb-7'>Email<span className='text-red-600'>*</span></h1>
      <input
        type="email"
        placeholder="Name@gmail.com"
        className="outline-none w-[360px] ml-5"
      />
    </div>
  </div>
  <div className="login_btn mt-4 mb-[170px]">
    <button className="bg-custom-btn text-white py-2 rounded-[10px] w-[250px] ml-[200px]">
    Request a reset link
    </button>
  </div>
</div> 
  </>
)   
}
function AuthForm({ isLogin, setLogForm, logForm, handleCloseModal ,setForgot ,forgot}) {



  if (isLogin) {
    return <Loginform logForm={logForm} setForgot={setForgot} forgot={forgot} setLogForm={setLogForm} handleCloseModal={handleCloseModal}/>
  }
  else if(forgot){
    return <ForgotForm setForgot={setForgot} forgot={forgot}/>
  }
  else {
    return <SignupForm logForm={logForm} setForgot={setForgot} forgot={forgot} setLogForm={setLogForm} handleCloseModal={handleCloseModal}/>
  }
   
  


 

}

export default AuthForm