import React, { useEffect, useState } from 'react';
import { useGetAllCategoryQuery, useGetSubCategoryQuery } from '../redux/productrtk/Product';
import Header from '../components/ProductCategory/Header';
import Dialog from '../components/ProductCategory/Dialog';
import LeftAccordion from '../components/ProductCategory/LeftAccordion';
import AllProductCard from '../components/ProductCategory/AllProductCard';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaChevronDown } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { TbArrowsDownUp } from 'react-icons/tb';
import HeaderDialog from '../components/ProductCategory/HeaderDialog';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SiFoodpanda } from 'react-icons/si';

const SearchProducts = () => {
  const [id, setId] = useState(1);
  const [apiData, setApiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [subset, setSubset] = useState([]);
  const CardsPerPage = 5;
  const startIndex = currentPage * CardsPerPage;
  const endIndex = startIndex + CardsPerPage;
  const { category } = useParams();
  const navigate = useNavigate();

  const { data: categories, isLoading: categoryLoading } = useGetAllCategoryQuery();
  const { data: subCategory, isLoading: subCategoryLoading, refetch } = useGetSubCategoryQuery(id);

  const searchProduct = useSelector((state) => state.search.searchProduct);
  const searchLoading = useSelector((state) => state.search.searchLoading);
  const searchQuery = useSelector((state) => state.search.searched)
  console.log("this is searchProduct", searchProduct);

  useEffect(() => {
    if (searchProduct?.data?.results) {
      console.log("this function run 1")
      setApiData(searchProduct?.data?.results);
      setTotalPages(Math.ceil(searchProduct?.data?.results?.length / CardsPerPage));
    }
  }, [searchProduct,subset]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    refetch();
  }, [id]);

  const updatedHeaderData = categories?.filter((i) => i.parent == null);


  useEffect(() => {
    console.log(apiData)
    if (apiData?.length > 0) {
      setSubset(apiData?.slice(startIndex, endIndex));
      console.log("this function run 2")
    } else {
      const subsetData = Array.isArray(searchProduct?.data?.results) ? searchProduct?.data?.results?.slice(startIndex, endIndex) : [];
      
      setSubset(subsetData);
    }
  }, [apiData, currentPage]);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const [viewModal, setViewModal] = useState(false);
  const [sortType, setSortType] = useState(0);

  const handleClick = () => {
    navigate('/allproducts');
  };

  const [loading, setLoading] = useState(false);
  const [activeSlide, setActiveSlide] = useState("");
  const [activeSlideCategory, setActiveSlideCategory] = useState("");
  const [optionVisibility, setOptionVisibility] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setSliderIndex(swiper.realIndex);
  };

  const sortMode = ["default", "popularity", "average rating", "latest", "Low To High", "High to Low"];
  function sortData(query) {
    setLoading(true);
    switch (query) {
      case 0:
        setApiData(searchProduct?.data?.results);
        break;
      case 1:
        const popularitySortedData = [...apiData];
        popularitySortedData.sort((a, b) => b.popularity - a.popularity);
        setApiData(popularitySortedData);
        break;
      case 2:
        const avgRatingSortedData = [...apiData];
        avgRatingSortedData.sort((a, b) => b.avgRating - a.avgRating);
        setApiData(avgRatingSortedData);
        break;
      case 3:
        const byDateSortedData = [...apiData];
        byDateSortedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setApiData(byDateSortedData);
        break;
      case 4:
        const lowToHighSortedData = [...apiData];
        lowToHighSortedData.sort((a, b) => a.price - b.price);
        setApiData(lowToHighSortedData);
        break;
      case 5:
        const highToLowSortedData = [...apiData];
        highToLowSortedData.sort((a, b) => b.price - a.price);
        setApiData(highToLowSortedData);
        break;
      default:
        break;
    }
    setLoading(false);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [currentPage]);

  return (
    <div>
      {
        searchLoading ? <div className="w-screen h-screen text-3xl font-semibold flex justify-center items-center"><div className="custom-loader"></div></div> : 
        <div>
          <div className="md:pt-5 pt-48 flex flex-col gap-y-[29px] md:gap-y-[16px]">
            <div className=" w-11/12 flex justify-between mx-auto items-center">
              <Header searchQuery={searchQuery} />
              <div className="md:hidden flex relative ">
                <button className="flex items-center h-fit text-custom-text mr-[32px]" onClick={() => setViewModal(!viewModal)}>
                  Sort by {sortMode[sortType]} <TbArrowsDownUp className="ml-[10px]" />
                </button>
                <Dialog visibility={viewModal} setVisibility={setViewModal} sortType={sortType} setSortType={setSortType} sortData={sortData} />
                {/* <span>{`Showing ${startIndex+1} - ${endIndex > searchProduct?.data?.length ? searchProduct?.data?.length : endIndex} of ${apiData?.length > 0 ? apiData?.length : searchProduct?.data?.length} Results`}</span> */}
                <span>{`Showing ${startIndex+1} - ${endIndex > subset?.length ? subset?.length : endIndex} of ${endIndex > subset?.length ? subset?.length : endIndex} Results`}</span>
              </div>
            </div>

            <div className="w-11/12 md:overflow-x-scroll mx-auto hidden md:flex items-center md:gap-x-3 text-[12px] font-semibold h-fit">
              <div className="w-[30%] pr-2 border-r-2 border-r-slate-600" onClick={handleClick}>All Products </div>
              <Swiper
                slidesPerView={3}
                spaceBetween={6}
                pagination={false}
                keyboard={true}
                mousewheel={{forceToAxis:true,thresholdDelta:30}}
                onSlideChange={(swiper) => handleSlideChange(swiper)}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                breakpoints={{
                  370:{
                    slidesPerView:1.75,
                   
                  },
                  400:{
                    slidesPerView:1.75,
                    
                  },
                  500: {
                    slidesPerView: 2.5,
                  
                  },
                }}
                className="mySwiper"
              >
                {
                  updatedHeaderData?.map((item) => (
                    <SwiperSlide className="font-semibold relative" key={item?.id} onClick={() => {setId(item?.id); setActiveSlide(item.category_name)}}>
                      <div className={`${activeSlide === item.category_name ? "text-[#A7C539] border-2 border-[#A7C539]" : ""}  flex items-center p-[6px] rounded text-[12px] gap-x-1`} onClick={() => setOptionVisibility(!optionVisibility)}>
                        <div>
                          {
                            activeSlideCategory && activeSlide === item.category_name ? <div>{activeSlideCategory}</div> : <div>{item.category_name}</div>
                          }
                          
                        </div>
                        {
                          activeSlide === item.category_name && <FaChevronDown fill="#A7C539"/>
                        }
                      </div>
                    </SwiperSlide>
                  ))
                }
              </Swiper>
              <HeaderDialog visibility={optionVisibility} setVisibility={setOptionVisibility} activeSlideCategory={activeSlideCategory} setActiveSlideCategory={setActiveSlideCategory} activeSlide={activeSlide} apiData={apiData} setApiData={setApiData} optionData={subCategory?.products}/>
            </div>
      
            <div className="hidden md:flex relative md:w-11/12 md:mx-auto">
              <button className="flex items-center h-fit text-custom-text mr-[32px]" onClick={() => setViewModal(!viewModal)}>
                Sort by {sortMode[sortType]} <TbArrowsDownUp className="ml-[10px]" />
              </button>
              <Dialog visibility={viewModal} setVisibility={setViewModal} sortType={sortType} setSortType={setSortType} sortData={sortData} />
            </div>

            <div className="flex w-11/12 mx-auto justify-between">
              <div className="md:hidden w-fit flex flex-col gap-y-4">
                <div className="font-lato font-bold underline text-xl">Category</div>
                <LeftAccordion setApiData={setApiData} apiData={apiData}/>
              </div>
          
        

              <div className="flex flex-col w-[75%] md:w-full">
                {
                  subset?.length > 0 ? 
                  <div>
                  <div className="grid justify-end ml-2 pt-[60px] w-full gap-x-[12px] gap-y-[73px] md:justify-between md:grid-cols-2 grid-cols-3">
                  {subset?.slice(startIndex, endIndex)?.map((item, index) => {
                  return (<AllProductCard key={index} item={item} />)
                  })} 
                </div>

                <div className="flex justify-center items-center">
                  <ReactPaginate
                    pageCount={totalPages}
                    // pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageClick}
                    forcePage={currentPage}
                    containerClassName="pagination"
                    activeClassName="active border-[1px] border-[rgba(0, 0, 0, 0.20)] rounded-full px-3 py-1 bg-[#00A6DB] text-white"
                    pageClassName="border-[1px] border-[rgba(0, 0, 0, 0.20)] rounded-full px-3 py-1"
                    className="flex gap-x-5 items-center mt-[650px]"
                    previousLabel={<FaAngleDoubleLeft fill='#949494'/>}
                    nextLabel={<FaAngleDoubleRight fill='#949494'/>}
                  />
                </div>
                  </div> : <div className='flex flex-col gap-y-5 justify-center items-center h-[30%]'> <SiFoodpanda size={96} color='#00A6DB'/> <div className='w-fit bg-[#00A6DB]  px-4 py-2 text-xl font-semibold text-white rounded-xl'>No Products Found !!</div></div>

                }
              </div>
            </div>

      
          </div>
        </div>
      }
    </div>
  )
}

export default SearchProducts