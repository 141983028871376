import { useEffect, useState } from "react";
import Header from "../../components/ProductCategory/Header";
import PageTopNavigation from "../../components/PageTopNavigation";
import { TbArrowsDownUp } from "react-icons/tb";
import ReactPaginate from 'react-paginate';
import Dialog from "../../components/ProductCategory/Dialog";
import HeaderDialog from "../../components/ProductCategory/HeaderDialog";
import LeftAccordion from "../../components/ProductCategory/LeftAccordion";
import AllProductCard from "../../components/ProductCategory/AllProductCard";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { FaChevronDown } from 'react-icons/fa';
import { useGetAllCategoryQuery, useGetAllProductQuery, useGetSubCategoryQuery, useSearchedProductsMutation } from "../../redux/productrtk/Product";
import { useDispatch, useSelector } from "react-redux";
import { setProduct } from "../../redux/slices/productSlice";
import '../../App.css';
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { useUserWhishQuery } from "../../redux/MyAccount/MyAccount";
import { setSearchTerm } from "../../redux/slices/search";


function AllProducts() {
  const [id, setId] = useState(1);
  const [apiData, setApiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const CardsPerPage = 12;
  const startIndex = currentPage * CardsPerPage;
  const endIndex = currentPage * CardsPerPage + CardsPerPage;
  const { category, industry } = useParams();
  const navigate = useNavigate();

  const { data: products, isError, isLoading: productLoading, refetch: productRefetch } = useGetAllProductQuery();
  const { data: categories, isLoading: categoryLoading } = useGetAllCategoryQuery();
  const { data: subCategory, isLoading: subCategoryLoading, refetch } = useGetSubCategoryQuery(id);
  const [SearchProdutApi] = useSearchedProductsMutation();
  const searchProduct = useSelector((state) => state.search.searchProduct);

  useEffect(() => {
    if (!products || productLoading) return;

    const filteredProducts = products.filter(product => {
      const matchesIndustry = industry 
        ? product?.industry_categories?.some(industryCategory => 
            industryCategory?.industry_category_name?.toLowerCase() === industry?.toLowerCase())
        : true;

      const matchesCategory = category 
        ? product?.category_name?.split(' ')?.join('')?.toLowerCase() === category?.split(' ')?.join('')?.toLowerCase()
        : true;

      return matchesIndustry && matchesCategory;
    });

    setApiData(filteredProducts);
  }, [industry, category, products, productLoading]);

  useEffect(() => {
    setTotalPages(apiData?.length > 0 ? Math.ceil(apiData.length / CardsPerPage) : Math.ceil(products?.length / CardsPerPage));
  }, [apiData, products]);

  useEffect(() => {
    setCurrentPage(0);
  }, [category, industry]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [category, industry, currentPage]);

  useEffect(() => {
    refetch();
  }, [id]);

  const updatedHeaderData = categories?.filter((i) => i.parent == null);
  const [subset, setSubset] = useState([]);

  useEffect(() => {
    if (apiData?.length > 0) {
      setSubset(apiData);
    } else {
      const subsetData = category 
        ? products?.filter((p) => p.category_name?.split(' ')?.join('')?.toLowerCase() === category) 
        : products?.slice(startIndex, endIndex);
      setSubset(subsetData);
    }
  }, [apiData, products, category, startIndex, endIndex,industry]);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const [viewModal, setViewModal] = useState(false);
  const [sortType, setSortType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeSlide, setActiveSlide] = useState("");
  const [activeSlideCategory, setActiveSlideCategory] = useState("");
  const [optionVisibility, setOptionVisibility] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setSliderIndex(swiper.realIndex);
  };

  const handleClick = () => {
    setActiveSlideCategory("");
    setActiveSlide("");
    productRefetch();
    setApiData(products);
    navigate('/allproducts');
  };

  const sortMode = ["default", "popularity", "average rating", "latest", "Low To High", "High to Low"];
  function sortData(query) {
    setLoading(true);
    switch (query) {
      case 0:
        const defaultSorted = products?.filter((p) => p?.category_name?.split(' ')?.join('')?.toLowerCase() === category);
        setApiData(defaultSorted);
        break;
      case 1:
        const popularitySortedData = [...apiData];
        popularitySortedData.sort((a, b) => b.popularity - a.popularity);
        setApiData(popularitySortedData);
        break;
      case 2:
        const avgRatingSortedData = [...apiData];
        avgRatingSortedData.sort((a, b) => b.avgRating - a.avgRating);
        setApiData(avgRatingSortedData);
        break;
      case 3:
        const byDateSortedData = category ? [...products?.filter((p) => p?.category_name?.split(' ')?.join('')?.toLowerCase() === category)] : [...products];
        byDateSortedData.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));
        setApiData(byDateSortedData);
        break;
      case 4:
        const lowToHighSortedData = [...apiData];
        lowToHighSortedData.sort((a, b) => a.price - b.price);
        setApiData(lowToHighSortedData);
        break;
      case 5:
        const highToLowSortedData = [...apiData];
        highToLowSortedData.sort((a, b) => b.price - a.price);
        setApiData(highToLowSortedData);
        break;
      default:
        break;
    }
    setLoading(false);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [currentPage]);

  return (
    <div>
      {
        (productLoading || categoryLoading) 
          ? <div className="w-screen h-screen text-3xl font-semibold flex justify-center items-center"><div className="custom-loader"></div></div> 
          : <div className="pb-[150px]">
              <div className="md:pt-5 pt-48 flex flex-col gap-y-[29px] md:gap-y-[16px]">
                <div className=" w-11/12 flex justify-between mx-auto items-center">
                  <Header activeSlideCategory={activeSlideCategory} setActiveSlideCategory={setActiveSlideCategory} setActiveSlide={setActiveSlide} setApiData={setApiData} />
                  <div className="md:hidden flex relative ">
                    <button className="flex items-center h-fit text-custom-text mr-[32px]" onClick={() => setViewModal(!viewModal)}>
                      Sort by {sortMode[sortType]} <TbArrowsDownUp className="ml-[10px]" />
                    </button>
                    <Dialog visibility={viewModal} setVisibility={setViewModal} sortType={sortType} setSortType={setSortType} sortData={sortData} />
                    <span>{`Showing ${startIndex + 1} - ${endIndex > subset?.length ? subset?.length : endIndex} of ${apiData?.length > 0 ? apiData?.length : products?.length} Results`}</span>
                  </div>
                </div>

                <div className="w-11/12 mx-auto hidden md:flex md:overflow-x-scroll items-center md:gap-x-3 text-[12px] myaccount_detail_list font-semibold h-fit">
                  <div className="w-[30%] text-center" onClick={() => handleClick()}>All products </div>
                  <div className="w-[1px]  h-[30px] bg-[#DDDDDE]"></div>
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={10}
                    pagination={false}
                    keyboard={true}
                    
                    mousewheel={{ forceToAxis: true, thresholdDelta: 30 }}
                    onSlideChange={(swiper) => handleSlideChange(swiper)}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    breakpoints={{
                      370: {
                        slidesPerView: 3,
                      },
                      400: {
                        slidesPerView: 3,
                      },
                      500: {
                        slidesPerView: 3,
                      },
                      640: {
                        slidesPerView: 4,
                      }
                    }}
                    className="mySwiper2"
                  >
                    {
                      updatedHeaderData?.map((item) => (
                        <SwiperSlide className="font-semibold relative" key={item?.id} onClick={() => { setId(item?.id); setActiveSlide(item.category_name) }}>
                          <div className={`${activeSlide === item.category_name ? "text-[#A7C539] border-2 border-[#A7C539]" : ""} w-fit flex items-center p-[6px] rounded text-[12px] gap-x-1`} onClick={() => setOptionVisibility(!optionVisibility)}>
                            <div>
                              {
                                activeSlideCategory && activeSlide === item.category_name ? <div>{activeSlideCategory?.substr(0, 5)}...</div> : <div onClick={() => navigate(`/allproducts/${item?.category_name?.split(' ').join('')?.toLowerCase()}`)}>{item.category_name}</div>
                              }
                            </div>
                            {
                              activeSlide === item.category_name && <FaChevronDown fill="#A7C539" />
                            }
                          </div>
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                  <HeaderDialog visibility={optionVisibility} setVisibility={setOptionVisibility} activeSlideCategory={activeSlideCategory} setActiveSlideCategory={setActiveSlideCategory} activeSlide={activeSlide} apiData={apiData} setApiData={setApiData} optionData={subCategory?.products} />
                </div>

                <div className="hidden md:flex relative md:w-11/12 md:mx-auto">
                  <button className="flex items-center h-fit text-custom-text mr-[32px]" onClick={() => setViewModal(!viewModal)}>
                    Sort by {sortMode[sortType]} <TbArrowsDownUp className="ml-[10px]" />
                  </button>
                  <Dialog visibility={viewModal} setVisibility={setViewModal} sortType={sortType} setSortType={setSortType} sortData={sortData} />
                </div>

                <div className="flex w-11/12 mx-auto justify-between">
                  <div className="md:hidden w-fit flex flex-col gap-y-4">
                    <div className="font-lato font-bold text-xl underline">Category</div>
                    <LeftAccordion setApiData={setApiData} apiData={apiData} />
                  </div>

                  <div className="flex flex-col w-[75%] md:w-full gap-y-[60px]">
                    <div className="grid justify-end pt-[60px] ml-2 w-full gap-x-[12px] gap-y-[73px] md:mt-[-40px] md:justify-between md:grid-cols-2 grid-cols-3">
                      {subset?.slice(startIndex, endIndex)?.map((item, index) => {
                        return (<AllProductCard key={index} item={item} />);
                      })}
                    </div>

                    <div className="relative min-h-[150px] md:min-h-[30px]">
  <div className="flex justify-center items-center absolute bottom-0 w-full">
    <ReactPaginate
      pageCount={totalPages}
      marginPagesDisplayed={2}
      onPageChange={handlePageClick}
      forcePage={currentPage}
      containerClassName="pagination"
      activeClassName="active border-[1px] border-[rgba(0, 0, 0, 0.20)] rounded-full px-3 py-1 bg-[#00A6DB] text-white"
      pageClassName="border-[1px] border-[rgba(0, 0, 0, 0.20)] rounded-full px-3 py-1"
      className="flex gap-x-5 items-center"
      previousLabel={<FaAngleDoubleLeft fill='#949494' />}
      nextLabel={<FaAngleDoubleRight fill='#949494' />}
    />
  </div>
</div>
                  </div>
                </div>

              </div>
            </div>
      }
    </div>
  );
}

export default AllProducts;
