import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useIndividualProductCustomOptionMutation } from '../../redux/individualProduct/individualProduct';
import { useGetSubCategoryQuery } from '../../redux/productrtk/Product';
import AllProductCard from '../ProductCategory/AllProductCard';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper/modules";
import LeftBtn from '../../assets/img/icons/slider_left_btn.svg'
import Button from '../../assets/img/slider_button.png'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import SliderCard from "../../pages/cartscreen/SliderCard";

const BuyTogether = ({productData}) => {
    const id = productData?.category?.id;
    const {id:paramId} = useParams();
    const [ProductQtyApi, {isLoading: productLoading}] = useIndividualProductCustomOptionMutation();
    const[loading, setIsLoading] = useState(false);
    const[products, setProducts] = useState([]);

    console.log("Related Products", products, loading);

    const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL
    const token = localStorage.getItem("token")

    const [sliderIndex, setSliderIndex] = useState(0);
    const handleSlideChange = (swiper) => {
      setSliderIndex(swiper.realIndex);
    };

    const relateds = async () => {
      setIsLoading(true);
      try {
          const body = { product_id_list: [paramId] };
          console.log("Body : ", body);
          const response = await axios.post(`${BASE_URL}/api/productsheet/related-products/`, body);
          const res = response?.data;
          setProducts(res);
          setIsLoading(false);
      } catch (error) {
          console.log(error);
          setIsLoading(false);
      }
  };
  
  useEffect(()=>{
    relateds()
  },[paramId])

    const {data, isLoading} = useGetSubCategoryQuery(id);
    const [minArray, setMinArray] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const promises = data?.products?.map(async (p) => {
              const productBody = {
                product_id: p.id,
              };
              const response = await ProductQtyApi(productBody);
              if (response) {
                return response.data?.Quantity[0]; // Assuming Quantity is an array
              }
            });
    
            const minQuantities = await Promise.all(promises);
            setMinArray(minQuantities);
          } catch (err) {
            console.log(err);
          }
        };
    
        fetchData();
      }, [data]);

      var relatedProducts = data?.products;
      if(relatedProducts) {
        relatedProducts = [...relatedProducts];
      }

      if(minArray?.length === relatedProducts?.length) {
        for (let i = 0; i < minArray?.length; i++) {
            // relatedProducts[i].minQty = minArray[i];
            const newObj = { ...relatedProducts[i] };
            // Add minQty property to the new object
            newObj.minQty = minArray[i];
            // Replace the object in arr2 with the new object
            relatedProducts[i] = newObj;
        }
      }

  return (
    <div className="w-full flex flex-col gap-y-9">
      <div className='flex flex-row w-full justify-between items-center'>
        <div className="text-[32px] font-medium md:hidden block">Customers who bought this item also bought</div>
        <div className="text-[24px] md:font-medium hidden md:block">Related Products</div>
        <div className='flex controller items-center'>
          {sliderIndex === 0 ? 
            <img src={LeftBtn} className='swiper_button_prev3 w-[20px] h-[14px]  cursor-pointer '/>
            :   <img src={Button}    className={`swiper_button_prev3 w-[20px] h-[14px] rotate-180 mt-[1px]  cursor-pointer md:h-[8px] md:w-[14px] `}/>
              
          }
          <img src={Button} className='swiper_button_next3 w-[20px] h-[14px] ml-5 cursor-pointer' alt="next"/>
        </div>
      </div>

      <div className='w-full'>
        <Swiper
          slidesPerView={4.10}
          spaceBetween={30}
          navigation={{
            nextEl: ".swiper_button_next3",
            prevEl: ".swiper_button_prev3",
          }}
          pagination={false}
          keyboard={true}
          mousewheel={{forceToAxis:true,thresholdDelta:30}}
          onSlideChange={(swiper) => handleSlideChange(swiper)}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          breakpoints={{
            300: { spaceBetween: 0, slidesPerView: 1.15 },
            320: { spaceBetween: 0, slidesPerView: 1.15 },
            360: { spaceBetween: 10, slidesPerView: 1.10 },
            412: { spaceBetween: 0, slidesPerView: 1.15 },
            425: { spaceBetween: 0, slidesPerView: 1.15 },
            500: { spaceBetween: 0, slidesPerView: 1.15 },
            768: { slidesPerView: 2.5, spaceBetween: 0 },
            1024: { slidesPerView: 3.10, spaceBetween: 10 },
            1440: { slidesPerView: 3.10, spaceBetween: 20 },
          }}
       
          className="relative place-content-start"
        >
          {
            products?.map((p) => (
              <SwiperSlide className='w-full'><SliderCard item={p}/></SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </div>
  )
}

export default BuyTogether