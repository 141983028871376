import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const QandA = (props) => {
  const points = props.subject.split(/\n\s*\d+\.\s+/).filter(Boolean);

  return (
    <>
      <div className='w-[100%]'>
        <div className='mt-[22px] md:mt-[12px]'>
          {!props.title.includes(".") && (
            <h2 className="md:text-[12px] text-[18px] text-custom-text-p mb-[12px] md:mb-[8px] leading-none md:text-black font-semibold md:font-[600]">
              {props.title}
            </h2>
          )}
          {/* {points.map((point, index) => (
            // Render each point in a new <p> tag
           
          ))} */}
          <p className="text-[#2f2f2f] leading-normal md:leading-4 md:text-[12px] font-normal md:text-[400] md:text-black text-[18px]" >
          { ReactHtmlParser(props.subject) }
              {/* {point.trim()} */}
            </p>
        </div>
      </div>
    </>
  );
};

export default QandA;
