import { useEffect } from "react";

function Dialog({ visibility, setVisibility, setSortType, sortData }) {
    useEffect(() => {
        if (visibility) {
            const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = `${scrollBarWidth}px`;
        } else {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }
        // Cleanup when the component is unmounted
        return () => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        };
    }, [visibility]);

    return (
        <div className={`${visibility ? 'flex' : 'hidden'}`}>
            <div className={`fixed top-0 left-0 w-screen h-screen z-[150] bg-black opacity-60`} onClick={() => setVisibility(!visibility)}></div>
            <div className="z-[151] bg-white absolute top-[30px] left-[-50px] md:left-[0px] px-[8px] py-[16px] flex flex-col items-start w-[15rem] rounded-[12px]">
                <button className="mb-[12px] hover:bg-[#ADADAD] transition duration-300 w-full text-left px-[8px] py-1 rounded-md" onClick={() => { setSortType(0); setVisibility(!visibility); sortData(0) }}>Default sorting</button>
                <button className="mb-[12px] hover:bg-[#ADADAD] transition duration-300 w-full text-left px-[8px] py-1 rounded-md" onClick={() => { setSortType(3); setVisibility(!visibility); sortData(3) }}>Sort by latest</button>
                <button className="mb-[12px] hover:bg-[#ADADAD] transition duration-300 w-full text-left px-[8px] py-1 rounded-md" onClick={() => { setSortType(4); setVisibility(!visibility); sortData(4) }}>Sort by Price: Low to high</button>
                <button className="hover:bg-[#ADADAD] transition duration-300 w-full text-left px-[8px] py-1 rounded-md" onClick={() => { setSortType(5); setVisibility(!visibility); sortData(5) }}>Sort by Price: High to low</button>
            </div>
        </div>
    );
}

export default Dialog;
