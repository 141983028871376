import { useState, useEffect } from 'react';
import google_icon from '../assets/img/google_icon.png';
// import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

function GoogleAuthButton({ children }) {
    const [userData, setUserData] = useState(null);
    const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL

    

    const fetchUserData = async () => {
        try {
            const clientId = '778802192461-o4bj93knh3ue3j2i3j6f84kr10t3s2jp.apps.googleusercontent.com';
const redirectUri = `https://api.packagingsimplified.com/api/user/loginr/google/`
const scope = 'openid email profile';
const responseType = 'code';
            window.location.href=`https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`
           
        } catch (error) {
            console.error('Error fetching user data:', error);
            return null;
        }
    };


    return (
      <button className='border-2 rounded border-blue w-full hover:bg-custom-btn hover:text-white transition duration-300 p-[10px] text-custom-text flex justify-between items-center mt-[16px] h-[44px] max-h-[44px]' onClick={fetchUserData}>
      <img src={google_icon} alt="google logo" className='md:h-[16px] md:w-[16px]'/>
      <span>{children}</span>
      <span></span>
  </button>
    );
}

export default GoogleAuthButton;
