import React, { useEffect, useState, useRef } from 'react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import LeftBtn from '../../assets/img/icons/slider_left_btn.svg';
import Button from '../../assets/img/slider_button.png';

const ProductDescription = ({ descData }) => {
    const [sliderIndex, setSliderIndex] = useState(0);
    const [slidesPerView, setSlidesPerView] = useState(getSlidesPerView());
    const [divWidth, setDivWidth] = useState(getDivWidth());
    const swiperRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setSlidesPerView(getSlidesPerView());
            setDivWidth(getDivWidth());
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (swiperRef.current) {
            const translateValue = -20; 
            swiperRef.current.setTranslate(translateValue);
        }
    }, [swiperRef]);

    const handleSwiper = (swiper) => {
        swiperRef.current = swiper;
        swiper.navigation.update();
    };

    const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

    function getDivWidth() {
        return window.innerWidth <= 1235 ? '500px' : '450px';
    }

    function getSlidesPerView() {
        return window.innerWidth <= 1232 ? 2 : 2.5;
    }

    const handleSlideChange = (swiper) => {
        setSliderIndex(swiper.realIndex);
    };


    console.log(descData?.title)
  return (
    <div>
  <div className={`flex flex-col md:hidden gap-y-9 w-full`}>
                <div className='flex justify-between items-center'>
                    <div className='flex flex-col gap-y-[6px]'>
                        <div className='text-[18px] font-semibold'>{descData?.title}</div>
                        <div className='text-[32px] font-medium'>{descData?.sub_title}</div>
                    </div>
                    <div className='flex items-center justify-end'>
                        {sliderIndex === 0 ? (
                            <img src={LeftBtn} className='swiper_button_prev8 w-[20px] h-[14px] cursor-pointer' />
                        ) : (
                            <img src={Button} className={`swiper_button_prev8 w-[20px] h-[14px] rotate-180 mt-[1px] cursor-pointer md:h-[8px] md:w-[14px]`} />
                        )}
                        <img src={Button} className='swiper_button_next8 w-[20px] h-[14px] ml-5 cursor-pointer' alt="next" />
                    </div>
                </div>
                <div className='w-full'>
                    <Swiper
                        onSwiper={handleSwiper}
                        slidesPerView={slidesPerView}
                        spaceBetween={10}
                        navigation={{
                            nextEl: ".swiper_button_next8",
                            prevEl: ".swiper_button_prev8",
                        }}
                        pagination={false}
                        keyboard={true}
                        mousewheel={{ forceToAxis: true, thresholdDelta: 30 }}
                        onSlideChange={handleSlideChange}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        className="mySwiper"
                    >
                        {descData?.description_cards.map((descCard, index) => (
                            <SwiperSlide className='flex-col' key={index}>
                            <div 
                                className='border-[1px] rounded-3xl w-[450px] p-6 flex flex-col gap-y-3 h-[580px]' // Fixed height
                                style={{ width: divWidth }}
                            >
                                {descCard?.image && 
                                    <img 
                                        src={`${descCard?.image}`} 
                                        className='pb-[16px] object-cover' // Adjust padding and image fit
                                        style={{ maxHeight: '500px', width: '100%' }} // Ensures the image fits within the height
                                        alt={descCard?.title}
                                    />
                                }
                                <div className='text-[24px] font-medium '>{descCard?.title}</div> {/* Use truncate for long titles */}
                                <div className='text-[20px] font-medium text-[#6D6E70] text-justify overflow-hidden'>{descCard?.sub_title}</div> {/* Handle overflow for sub-title */}
                            </div>
                        </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>

        <div className='hidden md:flex md:flex-col md:gap-y-6'>
            <div className='flex flex-col gap-y-2'>
                <div className='text-[12px]  font-normal'>{descData?.title}</div>

                <div className='text-[24px] font-medium'>{descData?.sub_title}</div>
            </div>


            <div className='flex flex-col gap-y-6'>
                <Swiper
                    // direction='vertical'
                    slidesPerView={2.10}
                    spaceBetween={10}
                    navigation={{
                        nextEl: ".swiper_button_next3",
                        prevEl: ".swiper_button_prev3",
                    }}
                    pagination={false}
                    keyboard={true}
                    mousewheel={{forceToAxis:true,thresholdDelta:30}}
                    onSlideChange={(swiper) => handleSlideChange(swiper)}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className="mySwiper"
                >
                    {
                        descData?.description_cards.map((descCard) => (
                            <SwiperSlide>
                                <div className='flex flex-col gap-y-6 border-[1px] border-[#2f2f2f] rounded-[12px] h-[300px]'>
                                    <div>
                                        {descCard?.image && <img  src={`${descCard?.image}`} className=' rounded-tl-xl rounded-tr-xl'/>}
                                    </div>

                                    <div className='px-3 flex flex-col gap-y-2'>
                                        <div className='text-[14px] font-semibold text-left'>{descCard?.title}</div>
                                        <div className='text-left text-[14px] font-normal leading-[18px] pb-3'>{descCard?.sub_title}</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    </div>
  )
}

export default ProductDescription