import React from 'react'

const ProductRelatedInfo = ({info, pos}) => {
    const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
    console.log(info)
    
  return (
    <div>
      <div className={`${pos} flex md:hidden items-center w-full justify-between py-[60px]`}>
        <div className='w-[50%] flex flex-col justify-center p-32 gap-y-6'>
          <div className='text-[32px] font-medium font-lato'>{info?.title}</div>
          <div className='text-[18px] font-lato font-normal'>{info?.sub_title}</div>
       </div>

       <div className='w-[50%] flex justify-end'>
          <img src={`${info?.image}`} className=' rounded-xl'/>
       </div>
      </div>

      <div className='hidden md:flex md:flex-col md:gap-y-6'>
        <div>
          <img src={`${info?.image}`} className=' rounded-xl'/>
        </div>

        <div className='flex flex-col gap-y-2'>
          <div className='text-[24px] font-medium'>{info?.title}</div>
          <div className='text-[16px] font-normal'>{info?.sub_title}</div>
          <div className='flex justify-center items-center border-[1px] border-[#00A6DB] rounded-[4px] px-2 py-[10px] text-[12px] font-semibold text-[#00A6DB]'>Explore More</div>
        </div>
      </div>
    </div>
  )
}

export default ProductRelatedInfo