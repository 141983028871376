import React, { useEffect, useState } from 'react'
import { IoIosHeartEmpty } from 'react-icons/io'
import { IoShareSocialOutline } from 'react-icons/io5'
import CartProduct from '../../assets/img/cart_slider.svg'
import { Link } from 'react-router-dom'


const SliderCard = ({products,item}) => {
    const handleShare = async()=>{
      const currentDomain = window.location.origin;
        navigator.share({
            text:'Checkout this blog',
            url:`${currentDomain}/allproducts/product/${item?.product_id}`,
            title:item?.Product
           })
    }

    console.log("this is ",item)


  return (
    <>
          <div className='w-[300px] relative '>
                <div className='absolute z-50 top-3 right-6'>
                <div className='flex justify-end items-center'>
                    <IoShareSocialOutline onClick={handleShare} className='text-custom-text ml-2 w-[20px] cursor-pointer h-[20px]'/>
                </div>


                </div>
                <Link to={`/allproducts/product/${item?.product_id}`}>
                <img src={item?.image?item?.image:CartProduct}/>
                </Link>

                <div className='details_1 text-left leading-5 pt-[2px] pl-1'>
                    <p className='text-[16px] font-[600] text-[#2F2F2F]'>{item?.Product}</p>
                    {item?.Quantity && item?.Quantity?.length > 0 && (
                    <p className='text-[16px] font-[400] text-[#2F2F2F]'>Minimum quantity: {item.Quantity[0]}</p>
                )}
                </div>

                </div>
    </>
  )
}

export default SliderCard