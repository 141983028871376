import React, { useState } from 'react'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import LeftBtn from '../assets/img/icons/slider_left_btn.svg'
// import Button from '../assets/img/slider_button.png'
import LeftBtn from '../../assets/img/icons/slider_left_btn.svg'
import Button from '../../assets/img/slider_button.png'
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const SubProductDetails = ({subProductData}) => {
    const [sliderIndex, setSliderIndex] = useState(0);
    const handleSlideChange = (swiper) => {
        setSliderIndex(swiper.realIndex);
    };

    console.log("Sub product details", subProductData);

    

  return (
    <div className='w-full'>
        <div className='w-full flex md:hidden bg-[#EDDABA]'>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                navigation={{
                    nextEl: ".swiper_button_next3",
                    prevEl: ".swiper_button_prev3",
                }}
                pagination={false}
                keyboard={true}
                mousewheel={{forceToAxis:true,thresholdDelta:30}}
                onSlideChange={(swiper) => handleSlideChange(swiper)}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                // breakpoints={{
                //     370:{
                //         slidesPerView:1.75,
                //     },
                //     400:{
                //         slidesPerView:1.75,
                //     },
                //     500: {
                //         slidesPerView: 2.5,
                //     },
                // }}
                className="mySwiper"
            >
                {
                    subProductData?.map((prod, index) => (
                        <SwiperSlide key={index}>
                            <div className='flex justify-between bg-[#EDDABA] rounded-2xl w-full'>
                                <div className=' pl-16 flex flex-col text-left w-[40%] h-[560px]'>
                                    <div className='pt-12 text-[18px] font-semibold pb-2'>{prod?.title}</div>

                                    <div className='text-[24px] font-medium pb-6'>{prod?.sub_title}</div>
                                    <div className='pl-6'>
                                        <ul>
                                            {
                                                prod?.points.map((point) => (<li className=' list-disc text-[18px] font-normal'>{point?.point}</li>))
                                            }
                                        </ul>
                                    </div>

                                    <div className='flex items-end h-full mb-5 justify-end'>
                                        {
                                            sliderIndex === 0 ? 
                                            <img src={LeftBtn} className={`swiper_button_prev3 w-[20px] h-[14px]  cursor-pointer `}/>
                                            :   <img src={Button}    className={`swiper_button_prev3 w-[20px] h-[14px] rotate-180 mt-[1px]  cursor-pointer md:h-[8px] md:w-[14px] `}/>
              
                                        }
                                        <img src={Button} className={`swiper_button_next3 w-[20px] h-[14px] ml-5 cursor-pointer `} alt="next"/>
                                    </div>
                                </div>

                                <div className="w-1/2">
                                    <img src={`${prod?.image}`} className='w-full h-[560px] rounded-2xl'/>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>


        <div className='hidden  md:flex md:flex-col md:items-center md:justify-center md:w-full md:gap-y-9 bg-white'>
            {
                subProductData?.map((prod, index) => (
                    <div className='w-full flex flex-col gap-y-6' key={index}>
                        <div className='w-full'>
                            <img src={`${prod?.image}`} className='rounded-2xl w-full'/>
                        </div>

                        <div className='flex flex-col gap-y-2'>
                            <div className=' text-[20px] md:text-[35px] font-normal '>{prod?.title}</div>
                            <div className='text-[24px] font-medium'>{prod?.sub_title}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default SubProductDetails