import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const MyFAQ = createApi({
  reducerPath: "MyFAQ",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
  }),
  endpoints: (builder) => ({
    getallFAQS: builder.query({
      query: () => "/api/faq/questions/get-all/",
    }),
    getallaboutus: builder.query({
      query: () => "/api/about-us/get-about-us/",
    }),
  }),
});

export const { useGetallFAQSQuery, useGetallaboutusQuery } = MyFAQ;
