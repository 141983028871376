import { useEffect } from "react";
import {MdOutlineKeyboardArrowRight} from 'react-icons/md'
import { useGetAllProductQuery } from "../../redux/productrtk/Product";

function Dialog({ visibility, setVisibility, activeSlideCategory, setActiveSlideCategory, activeSlide, apiData, setApiData, optionData }) {
    const {data: products} = useGetAllProductQuery();
    useEffect(()=>{
        if(visibility)
        {
            document.body.style.overflow = "hidden";
        }
        else
        {
            document.body.style.overflow = "auto";
        }
    }, [visibility])
    return (
        <div className={`${visibility ? 'flex' : 'hidden'}`}>
            <div className={`fixed top-[20%] left-0 w-screen h-screen z-[150] bg-black opacity-60`} onClick={() => setVisibility(!visibility)}></div>
            <div className="z-[151] shadow-md bg-white absolute top-[20%] left-[-50px] md:left-[15px] px-[12px] py-[16px] flex items-start w-11/12 mx-auto gap-x-3 flex-wrap gap-y-3">
                {/* <button className="mb-[16px] hover:bg-[#ADADAD] transition duration-300 w-full text-left px-[2px]" onClick={()=>{setSortType(0); setVisibility(!visibility); sortData(0)}}>Default sorting</button>
                <button className="mb-[16px] hover:bg-[#ADADAD] transition duration-300 w-full text-left px-[2px]" onClick={()=>{setSortType(1); setVisibility(!visibility); sortData(1)}}>Sort by popularity</button>
                <button className="mb-[16px] hover:bg-[#ADADAD] transition duration-300 w-full text-left px-[2px]" onClick={()=>{setSortType(2); setVisibility(!visibility); sortData(2)}}>Sort by average rating</button>
                <button className="mb-[16px] hover:bg-[#ADADAD] transition duration-300 w-full text-left px-[2px]" onClick={()=>{setSortType(3); setVisibility(!visibility); sortData(3)}}>Sort by latest</button>
                <button className="mb-[16px] hover:bg-[#ADADAD] transition duration-300 w-full text-left px-[2px]" onClick={()=>{setSortType(4); setVisibility(!visibility); sortData(4)}}>Sort by Price: Low to high</button>
                <button className="hover:bg-[#ADADAD] transition duration-300 w-full text-left px-[2px]" onClick={()=>{setSortType(5); setVisibility(!visibility); sortData(5)}}>Sort by Price: High to low</button> */}
                <div className="w-full">
                    {
                        activeSlideCategory ? <div className="flex items-center gap-x-1" onClick={() => setActiveSlideCategory("")}>{activeSlide}  <MdOutlineKeyboardArrowRight fill="#2F2F2F"/> <span>{activeSlideCategory}</span></div> : <div></div>
                    }
                </div>
                {
                    optionData?.map((item) => (<div className="border-[1px] p-[6px] rounded-[2px] text-[12px] font-normal" onClick={() => {setVisibility(!visibility); setActiveSlideCategory(item?.title); setApiData(products?.filter((i) => i.title === item.title))}}>{item.title}</div>))
                }
            </div>
        </div>
    )
}

export default Dialog