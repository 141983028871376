import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const Term = createApi({
    reducerPath: "Term",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_BASE_URL, 
    }),
    endpoints: (builder) => ({
        getAllTerms: builder.query({
            query: () => "api/termANDcondition/get-all",
        }),
    }),
});

export const { useGetAllTermsQuery } = Term;
