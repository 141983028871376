import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const RazorpaySimulator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { order_id, amount } = location.state || {};

  const simulatePayment = (status) => {
    const paymentResponse = {
      razorpay_payment_id: `pay_${Math.random().toString(36).substring(7)}`,
      razorpay_order_id: order_id,
      razorpay_signature: `signature_${Math.random().toString(36).substring(7)}`,
      amount: amount,
      status: status, 
    };

    navigate('/payment', { state: paymentResponse });
  };

  return (
    <div className="payment-simulator">
      <h1>Razorpay Payment Simulator</h1>
      <p>Order ID: {order_id}</p>
      <p>Amount: ₹{amount / 100}</p>
      <div>
        <button onClick={() => simulatePayment("success")}>Simulate Successful Payment</button>
        <button onClick={() => simulatePayment("failed")}>Simulate Failed Payment</button>
        <button onClick={() => simulatePayment("cancelled")}>Simulate Cancelled Payment</button>
      </div>
    </div>
  );
};

export default RazorpaySimulator;
