import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const Contact = createApi({
    reducerPath:"Contact",
    baseQuery:fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,  
    }),
    endpoints:(builder)=>({
        Contact:builder.mutation({
            query:(contact)=>({
                url:"/api/contact_us/create/",
                method:"POST",
                body:contact,
            }),
        }),
    })
})

export const {useContactMutation} = Contact