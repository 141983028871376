import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useGetAllParentCategoryQuery, useGetParentAllCategoryQuery } from '../../redux/productrtk/Product';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: 'none',
          '&:before': {
            display: 'none',
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          }
        }
      }
    }
  }
});

const Accordion2 = ({navbar}) => {
  const { data: parentCategories } = useGetAllParentCategoryQuery();
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [parentId,setparentId]=useState()
  const {data:parentcategorys,isLoading}=useGetParentAllCategoryQuery(parentId)


  console.log(parentcategorys)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };
  const navigate = useNavigate()
  const handleNavigate = (category)=> {
    navigate(`/allproducts/${category}`)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={`${navbar?'w-[100%]':'w-[60vw] '} ml-[2vw] font-lato border-none outline-none shadow-none`}>
        {parentCategories?.map((product, i) => (
          <Accordion
            key={i}
            expanded={expandedPanel === i}
            onChange={handleChange(i)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={()=>setparentId(product?.id)}>
              <Typography className="text-[12px] font-medium " >
                {product?.parent_category_name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
            {isLoading?<p>Loading....</p>:
              parentcategorys?.sub_categories?.map((item,index)=>(
              <Typography className='py-2 font-[700]' onClick={()=>handleNavigate(item?.category_name)}>
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium est in quam luctus, vitae posuere neque ultrices.
                Nullam nec pharetra sapien, sed suscipit quam. */}
                <p className='font-[800]'>{item?.category_name}</p>
              </Typography>
            ))

            }
              
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </ThemeProvider>
  );
};

export default Accordion2;
