import React, { useEffect, useState, useNavigate } from 'react'
import { useGetSubCategoryQuery } from '../../redux/productrtk/Product';
import { useIndividualProductCustomOptionMutation } from '../../redux/individualProduct/individualProduct';
import { Link, useParams } from 'react-router-dom'
import "../../index.css"
const RelatedProducts = ({productData}) => {

    const id = productData?.category?.id;
    const {id:paramId} = useParams()
    const [ProductQtyApi, {isLoading: productLoading}] = useIndividualProductCustomOptionMutation();

    const {data, isLoading} = useGetSubCategoryQuery(id);

    useEffect(() => {
      window.scrollTo({
        top:0,
        behaviour: 'smooth',
      })
    }, [paramId])
    
    // var minArray = [];
    const [minArray, setMinArray] = useState([]);

    // useEffect(() => {
    //     const getMinQty = async (pid) => {
    //         try {
    //             const productBody = {
    //                 product_id: pid,
    //             }
    
    //             const response = await ProductQtyApi(productBody);
    
    //             if(response) {
    //                 const mini = response?.data?.Quantity[0];
    //                 console.log("PID", pid);
    //                 setMinArray(prevState => [...prevState, mini]);
    //             }
    //         } catch (err) {
    //             console.log(err);
    //         }
    //     }

    //     data?.products?.slice(0, 2)?.forEach((p) => getMinQty(p?.id));
    // }, [])

    useEffect(() => {
        const fetchData = async () => {
          try {
            const promises = data?.products?.map(async (p) => {
              const productBody = {
                product_id: p?.id,
              };
              const response = await ProductQtyApi(productBody);
              if (response) {
                return response.data?.Quantity[0]; // Assuming Quantity is an array
              }
            });
    
            const minQuantities = await Promise.all(promises);
            setMinArray(minQuantities);
          } catch (err) {
            console.log(err);
          }
        };
    
        fetchData();
      }, [data]);

      var relatedProducts = data?.products?.filter((d) => d?.id != paramId);
      if(relatedProducts) {
        relatedProducts = [...relatedProducts];
      }

      if(minArray?.length === relatedProducts?.length) {
        for (let i = 0; i < minArray?.length; i++) {
            // relatedProducts[i].minQty = minArray[i];
            const newObj = { ...relatedProducts[i] };
            // Add minQty property to the new object
            newObj.minQty = minArray[i];
            // Replace the object in arr2 with the new object
            relatedProducts[i] = newObj;
        }
      }

      console.log("Related products", relatedProducts);

  return (
    <div className='w-full md:w-full'>
        {
            (!isLoading && !productLoading) ? <div>
        {relatedProducts?.length > 0 && <div className='text-[32px]  font-medium pb-6'>Related Products</div>}
        <div className='flex flex-col gap-y-6'>
            {
                relatedProducts?.slice(0,2)?.map((p) => (
                    <div className='flex flex-row justify-between items-center border-[#DDDDDE] border-[1px] rounded-lg w-full p-[8px] md:justify-between'>
                        <div className='flex flex-row gap-x-6 items-center'>
                            <div className='w-[110px] h-[115px] md:w-[88px] md:h-[88px]'>
                                <img src={p?.image} className='relimg' alt='Related Product'/>
                            </div>

                            <div className='flex flex-col md:pr-3'>
                                <div className='text-[18px] font-semibold md:text-[16px] md:font-medium'>{p?.title}</div>
                               
                            </div>
                        </div>

                        <Link to={`/allproducts/product/${p?.id}`} className='border-[#00A6DB] border-[1px] rounded py-[10px] px-[24px] text-[16px] font-semibold text-[#00A6DB] md:hidden'>
                            View More
                        </Link>

                        <Link to={`/allproducts/product/${p?.id}`} className='border-[#00A6DB] border-[1px] rounded py-[8px] px-[16px] text-[12px] font-semibold text-[#00A6DB]  hidden md:block'>
                            More
                        </Link>
                    </div>
                ))
            }
        </div>
        </div> : <div></div>

        }
    </div>
  )
}

export default RelatedProducts