import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const IndividualProduct = createApi({
    reducerPath: "IndividualProduct",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('token');
            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
      
            return headers;
          },
    }),
    endpoints: (builder) => ({
        getAllIndividualProduct: builder.query({
            query: (id) => `/api/product/${id}`,
        }),

        individualProductCustomOption: builder.mutation({
            query:(productBody) => ({
                url:"/api/productsheet/product-details/",
                method:"POST",
                body:productBody,
            }),
        }),

        productDynamicPrice: builder.mutation({
            query: (productPriceLoad) => ({
                url: "/api/productsheet/price-load/",
                method: "POST",
                body: productPriceLoad,
            }),
        }),
        addToCart: builder.mutation({
            query:(productData) => ({
                url:"/api/product/add-to-cart/",
                method:"POST",
                body:productData,
            }),
        }),
        productSpecifications: builder.query({
            query: (id) => `/api/productsheet/product-spec/${id}`,
        }),

        productMetaTags: builder.query({
            query: (id) => `/api/productsheet/${id}/meta-tags/`,
        }),
        ProductReviewDetail: builder.query({
            query: (id) => `/api/productsheet/${id}/get-product-reviews/`,
        }),
    }),
});

export const { useGetAllIndividualProductQuery, useAddToCartMutation, useIndividualProductCustomOptionMutation, useProductDynamicPriceMutation, useProductSpecificationsQuery, useProductMetaTagsQuery,useProductReviewDetailQuery } = IndividualProduct;