import React, { useState } from 'react'
import { useGetAllParentCategoryQuery, useGetParentAllCategoryQuery } from '../../redux/productrtk/Product';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

const MobileCategoryAccordian = ({onClose}) => {
    const { data: parentCategories } = useGetAllParentCategoryQuery();
    console.log(parentCategories[0])
    const [expandedPanel, setExpandedPanel] = useState(parentCategories?.[0]?.id);
    const [parentId, setParentId] = useState(parentCategories?.[0]?.id);
    const { data: parentCategoriesData, isLoading } = useGetParentAllCategoryQuery(parentId);

    const handlePanelClick = (id) => {
        setExpandedPanel((prev) => (prev === id ? null : id));
        setParentId(id);
    };
    const navigate = useNavigate()
    const handleNavigate = (category)=> {
      navigate(`/allproducts/${category}`)
      onClose()
    }

    return (
        <div className='w-[100%]'>
            {parentCategories?.map((item, index) => (
                <div key={index} className='w-[100%]'>
                    <div
                        className={`w-[100%] py-[8px] mb-2 flex justify-between md:font-medium items-center cursor-pointer ${expandedPanel === item?.id ? 'text-custom-text border-b-[2px] border-blue pb-1' : ''}`}
                        onClick={() => handlePanelClick(item?.id)}
                    >
                        <p>{item?.parent_category_name}</p>
                        <span className={`${expandedPanel === item?.id ? 'text-custom-text' : ''}`}></span>
                        {expandedPanel === item?.id ?<IoIosArrowUp className='text-custom-text'/>:<IoIosArrowDown className='text-black'/>

                        }
                        {/* <IoIosArrowDown className='text-black'/>
                        <IoIosArrowUp className='text-custom-text'/> */}
                    </div>
                    <div
                        className={`transition-max-height duration-500 ease-in-out overflow-hidden ${expandedPanel === item?.id ? 'max-h-[1000px]' : 'max-h-0'}`}
                    >
                    {expandedPanel === item?.id && (
                        <div className='w-[100%] overflow-y-scroll'>
                            {isLoading ? (
                                <p className='text-custom-text'>Loading...........</p>
                            ) : (
                                <div className='w-[100%] grid grid-cols-4 gap-[26px] min-h-[250px] py-2 px-2'>
    {parentCategoriesData?.sub_categories?.map((subItem, subIndex) => (
        <div className='w-[70px]' key={subIndex} onClick={() => handleNavigate(subItem?.category_name)}>
            <img src={subItem?.category_image} alt={subItem?.category_name} />
            <p className='text-center font-[12px]'>{subItem?.category_name}</p>
        </div>
    ))}
</div>
                            )}
                        </div>
                    )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MobileCategoryAccordian;
