import { useEffect } from "react"
import PageTopNavigation from "../components/PageTopNavigation";
import QandA from "../components/QandA";
import { useGetAllTermsQuery } from "../redux/termrtk/TermAndCondition";

function TermsAndConditions() {
    const {data,isError,isLoading} = useGetAllTermsQuery()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
        <div className="mt-[70px] ml-10 md:mt-1 md:ml-3">
        <PageTopNavigation firstRouteLink={'/'} firstRouteName={'Home'} secondRouteName={'Term and condition'}  />
        </div>
        <div className="md:mx-[12px] mt-[40px] md:mt-1 mx-[60px] mb-24 text-custom-text-p">

            <h1 className='md:text-[24px] md:text-black md:font-[500] text-[36px] text-[#2f2f2f] font-medium leading-none '>Terms & Conditions</h1>

            {data?
                data.map((item,index)=>(
                <QandA  key={index} title={item.title} subject={item.subject}/>
            )):'....LOADING'

            }
            
        </div>
        </>
    )
}

export default TermsAndConditions;