import React from 'react'
import { useParams } from 'react-router-dom'
import { useProductSpecificationsQuery } from '../../redux/individualProduct/individualProduct';

const ProductSpecification = ({ specData }) => {
  const { id } = useParams();

  const createMarkup = (text) => {
    return { __html: text.replace(/\r\n/g, '<br/>') };
  };

  return (
    <div className='flex flex-col gap-y-9 w-full'>
      <div>
        <div className='text-[32px] md:mt-7 font-medium'>Specifications</div>
      </div>

      <div className='flex flex-col place-self-end md:w-full w-[100%]'>
        {
          specData?.map((spec) => (
            <div key={spec.id} className='p-6 border-[1px] border-[#adadad] w-full'>
              <div className='text-[18px] font-semibold'>{spec?.spec}</div>
              <div className='text-[18px] font-normal' dangerouslySetInnerHTML={createMarkup(spec?.value)}></div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default ProductSpecification;
