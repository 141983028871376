import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import LeftBtn from '../../assets/img/icons/slider_left_btn.svg'
import Button from '../../assets/img/slider_button.png'
import {FaStar} from 'react-icons/fa'

const ProductReview = ({ratingData}) => {
    const [sliderIndex, setSliderIndex] = useState(0);
    const handleSlideChange = (swiper) => {
        setSliderIndex(swiper.realIndex);
    };

    console.log("this is ratingData",ratingData)

    var avgRating = ratingData?.results?.average_rating;
  return (
    <div>
        <div className='flex flex-col md:hidden'>
            <div className='text-[18px] font-semibold leading-normal'>Reviews</div>

            <div className='text-[32px] font-medium text-[#2f2f2f] pb-[36px]'>See what our Customer says</div>

            <div className='flex flex-row w-full gap-x-3'>
                <div className='w-[30%] flex flex-col items-center justify-center gap-y-3'>
                    <div className='text-[32px] font-medium '>{avgRating === 5 ? "Excellent" : avgRating === 4 ? "Nice" : avgRating === 3 ? "Good" : avgRating === 2 ? "Average" : "Bad"}</div>
                    {
                        avgRating === 5 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> </div> :
                        avgRating === 4 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#949494'/> </div> : 
                        avgRating === 3 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> </div> :
                        avgRating === 2 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> </div> : 
                        <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/></div>
                    }
                    <div className='text-[18px] font-normal'>Based on <span className='font-semibold'>{ratingData?.results?.review_customers_no}</span> reviews</div>
                </div>
            
            
                {
                    sliderIndex === 0 ? 
                    <img src={LeftBtn} className='swiper_button_prev3 w-[20px] h-[14px]  cursor-pointer place-self-center'/>
                    :   <img src={Button}    className={`swiper_button_prev3 w-[20px] h-[14px] rotate-180 mt-[1px]  cursor-pointer md:h-[8px] md:w-[14px] place-self-center`}/>
              
                }
            
                <Swiper
                    slidesPerView={3}
                    spaceBetween={10}
                    navigation={{
                        nextEl: ".swiper_button_next3",
                        prevEl: ".swiper_button_prev3",
                    }}
                    pagination={false}
                    keyboard={true}
                    mousewheel={{forceToAxis:true,thresholdDelta:30}}
                    onSlideChange={(swiper) => handleSlideChange(swiper)}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className="mySwiper w-[60%] justify-center"
                >
                    {
                        ratingData?.results?.review_details?.map((review, index) => (
                            <SwiperSlide key={index}>
                                <div className='flex flex-col gap-3'>
                                    <div className='flex justify-center'>
                                        {
                                            review?.rating === 5 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> </div> :
                                            review?.rating === 4 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#949494'/> </div> : 
                                            review?.rating === 3 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> </div> :
                                            review?.rating === 2 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> </div> : 
                                            <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/></div> 
                                        }
                                    </div>

                                    <div></div>
                                    <div className='text-left text-[18px] font-normal text-[#2f2f2f]'>{review?.review}</div>

                                    <div className='flex text-[16px] font-medium text-[#949494]'>
                                    <div className='border-r-[1px] border-[#949494] pr-1'>
  {review?.user?.first_name === '' && review?.user?.last_name === ''
    ? review?.user?.username
    : `${review?.user?.first_name} ${review?.user?.last_name}`}
</div>
                                        <div className='pl-1'>{review?.created_at}</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            
            
                <img src={Button} className='swiper_button_next3 w-[20px] h-[14px] cursor-pointer place-self-center' alt="next"/>
            
            </div>

        </div>

        <div className='hidden md:flex md:flex-col md:gap-y-[25px]'>
            <div className='flex flex-col'>
                <div className='text-[16px] font-normal'>Reviews</div>
                <div className='text-[24px] font-medium'>See what our customers says</div>
            </div>

            <div className='flex gap-x-9'>
                <div>
                    {
                        avgRating === 5 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> </div> :
                        avgRating === 4 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#949494'/> </div> : 
                        avgRating === 3 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> </div> :
                        avgRating === 2 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#A7C539'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> </div> : 
                        <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/> <FaStar fill='#949494'/></div>
                    }
                </div>

                <div className='flex items-center'>
                    <span>{avgRating}</span>
                    <span>{` (${ratingData?.results?.review_customers_no}) reviews)`}</span>
                </div>
            </div>

            <div className='flex border-[1px] border-[#00A6DB] text-[14px] text-[#00A6DB] w-fit px-6 py-[10px] font-semibold rounded-[4px] '>
                Write a review
            </div>

            <div className='border-[1px] '></div>

            <div className='text-[20px] font-medium'>
                Reviewd by {ratingData?.results?.review_customers_no} customers
            </div>

            <div className='flex flex-col gap-y-[15px]'>
                <div className='flex flex-col gap-y-[10px]'>
                    {/* <div>
                        {
                            ratingData[0]?.rating === 5 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539' size={20}/> <FaStar fill='#A7C539' size={20}/> <FaStar fill='#A7C539' size={20}/> <FaStar fill='#A7C539' size={20}/> <FaStar fill='#A7C539' size={20}/> </div> :
                            ratingData[0]?.rating === 4 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539' size={20}/> <FaStar fill='#A7C539' size={20}/> <FaStar fill='#A7C539' size={20}/> <FaStar fill='#A7C539' size={20}/> <FaStar fill='#949494' size={20}/> </div> : 
                            ratingData[0]?.rating === 3 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539' size={20}/> <FaStar fill='#A7C539' size={20}/> <FaStar fill='#A7C539' size={20}/> <FaStar fill='#949494' size={20}/> <FaStar fill='#949494' size={20}/> </div> :
                            ratingData[0]?.rating === 2 ? <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539' size={20}/> <FaStar fill='#A7C539' size={20}/> <FaStar fill='#949494' size={20}/> <FaStar fill='#949494' size={20}/> <FaStar fill='#949494' size={20}/> </div> : 
                            <div className='flex flex-row gap-x-1'><FaStar fill='#A7C539' size={20}/> <FaStar fill='#949494' size={20}/> <FaStar fill='#949494' size={20}/> <FaStar fill='#949494' size={20}/> <FaStar fill='#949494' size={20}/></div>
                        }
                    </div> */}

                    <div></div>

                    <div className='flex text-[14px] font-medium'>
                        {/* <div className='border-r-[1px] border-[#2f2f2f] pr-2'>{ratingData[0]?.user?.first_name} {ratingData[0]?.user?.last_name}</div> */}
                        {/* <div className='pl-2'>{formatDateFromTimestamp(ratingData[0]?.created_at)}</div> */}
                    </div>
                </div>

                {/* <div>{ratingData[0]?.description}</div> */}

                <div></div>
            </div>
        </div>
    </div>
  )
}

export default ProductReview