import { createSlice } from '@reduxjs/toolkit';
const MAX_STATE = 3; 
const stepperSlice = createSlice({
  name: 'stepper',
  initialState: 1,
  reducers: {
    increase: (state) => {
      if (state < MAX_STATE) {
        return state + 1;
      } else {
        return state;
      }
    },
    setNum: (state, action) => {
      if (action.payload <= MAX_STATE) {
        return action.payload;
      } else {
        return state;
      }
    },
  },
});

export const { increase, setNum } = stepperSlice.actions;
export default stepperSlice.reducer;
