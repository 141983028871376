import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const Product = createApi({
    reducerPath: "Product",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_BASE_URL, 
    }),
    endpoints: (builder) => ({
        getAllProduct: builder.query({
            query: () => "/api/product/",
        }),
        getAllCategory: builder.query({
            query: () => "/api/product/category",
        }),
        getSubCategory: builder.query({
            query: (id) => `/api/product/category/${id}`,
        }),
        searchedProducts: builder.mutation({
            query: (searchBody) => ({
                url: "/api/productsheet/search-bar/",
                method: "POST",
                body: searchBody,
            }),
        }),
        getIndustryFilter: builder.query({
            query: () => '/api/product/industry-category',
        }),
        getAllParentCategory: builder.query({
            query: () => "/api/product/parent-category/",
        }),
        getParentAllCategory: builder.query({
            query: (id) => `/api/product/parent-category/${id}/`,
        }),
        HomePageSliderProduct: builder.query({
            query: () => `/api/product/all-parent-categories-details/`,
        }),
        HappyClient: builder.query({
            query: () => `/api/productsheet/get-happy-clients/`,
        }),
        PartnerLogos: builder.query({
            query: () => `/api/productsheet/get-partners-logo-home/`,
        }),
        FindYourFeet: builder.query({
            query: () => `/api/productsheet/get-industry-cards-home/`,
        }),
        HomeBannerImage: builder.query({
            query: () => `/api/productsheet/get-banner-image/`,
        }),
    }),
});

export const {
    useGetAllProductQuery,
    useGetAllCategoryQuery,
    useGetSubCategoryQuery,
    useSearchedProductsMutation,
    useGetIndustryFilterQuery,
    useGetAllParentCategoryQuery,
    useGetParentAllCategoryQuery,
    useHomePageSliderProductQuery,
    useHappyClientQuery,
    usePartnerLogosQuery,
    useFindYourFeetQuery,
    useHomeBannerImageQuery
} = Product;
