import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";
import { useGetAllProductQuery } from '../../redux/productrtk/Product';

function Header({ activeSlideCategory, setActiveSlideCategory, setActiveSlide, setApiData, searchQuery }) {
  const location = useLocation();
  const locationArray = location.pathname.split('/').slice(1);
  const navigate = useNavigate();

  const { data: products, isError, isLoading: productLoading, refetch: productRefetch } = useGetAllProductQuery();

  const handleClick = () => {
    setActiveSlideCategory("");
    setActiveSlide("");
    navigate('/allproducts');
  };

  const handleSlide = (route) => {
    setActiveSlideCategory("");
    const filteredProducts = route ? products?.filter(p => p.category_name?.split(' ')?.join('')?.toLowerCase() === route) : products;
    setApiData(filteredProducts);
    navigate(`/allproducts/${route}`);
  };

  return (
    <div className="flex items-center mb-[40px] md:mb-2 flex-nowrap md:flex-wrap">
      <Link to="/">Home</Link>
      <IoIosArrowForward className="text-[#949494] mx-[7px]" />
      {
        locationArray.map((route, index) => {
          if (route === 'industry') {
            return null;
          }

          return (
            <div className="flex items-center" key={route}>
              <Link
                to={route === 'allproducts' ? `/allproducts` : ""}
                onClick={() => route === 'allproducts' ? handleClick() : handleSlide(route)}
                className={`${locationArray?.at(-1) === route || location.pathname === '/searchProduct' ? activeSlideCategory ? '' : 'text-[#A7C539] font-bold' : ''}`}
              >
                {route === 'allproducts'
                  ? "All Products"
                  : location.pathname === '/searchProduct'
                    ? `Search results for ${searchQuery || '...'}`
                    : route !== 'product' && decodeURIComponent(route[0].toUpperCase() + route.slice(1))}
              </Link>
              {index !== locationArray?.length - 1 && <IoIosArrowForward className="text-[#949494] mx-[16px]" />}
            </div>
          );
        })
      }
      {activeSlideCategory && <IoIosArrowForward className="text-[#949494] mx-[16px]" />}
      {activeSlideCategory && <div className='text-[#A7C539] font-bold'>{activeSlideCategory}</div>}
    </div>
  );
}

export default Header;
