import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import user from '../Slice/Slice';
import { Users } from '../authenticationrtk/UsersAuthApi';
import { Contact } from '../contactrtk/ContactRtk';
import { Term } from '../termrtk/TermAndCondition';
import { Private } from '../policyrtk/PrivatePolicy';
import stepperReducer from '../Steppr/Stepper';
import {MyAccount} from '../MyAccount/MyAccount'
import { MyFAQ } from '../faqrtk/FaqRtk';
import { Addresses } from '../address/Addresses';
import { IndividualProduct } from '../individualProduct/individualProduct'
import {Product} from '../productrtk/Product'
import productReducer from '../slices/productSlice'
import searchReducer from '../slices/search'

export const store = configureStore({
  reducer: {
    [Users.reducerPath]: Users.reducer,
    [Contact.reducerPath]: Contact.reducer,
    [Term.reducerPath]: Term.reducer,
    [Private.reducerPath]: Private.reducer,
    stepper: stepperReducer, 
    [MyAccount.reducerPath]:MyAccount.reducer,
    [MyFAQ.reducerPath]:MyFAQ.reducer,
    [Addresses.reducerPath]:Addresses.reducer,
    [IndividualProduct.reducerPath]:IndividualProduct.reducer,
    [Product.reducerPath]:Product.reducer,
    product: productReducer,
    search: searchReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      Users.middleware,
      Contact.middleware,
      Term.middleware,
      Private.middleware,
      MyAccount.middleware,
      MyFAQ.middleware,
      Addresses.middleware,
      IndividualProduct.middleware,
      Product.middleware
    ),
});

setupListeners(store.dispatch);
